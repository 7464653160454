import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const SettingsNav = (props) => (
  <svg {...props} viewBox="0 0 20 20" fill={props.color}>
    <path d="M11.224 0c.339 0 .613.274.613.612v2.173c.68.176 1.221.348 1.656.546l1.557-1.557a.612.612 0 0 1 .866 0l2.31 2.31c.239.239.239.627 0 .866l-1.557 1.556c.198.435.37.978.547 1.657h2.172c.338 0 .612.274.612.613v2.448a.612.612 0 0 1-.612.613h-2.172c-.177.682-.349 1.222-.547 1.657l1.557 1.557a.612.612 0 0 1 0 .866l-2.309 2.309a.612.612 0 0 1-.866 0l-1.558-1.557c-.434.198-.974.37-1.656.547v2.172a.612.612 0 0 1-.613.612H8.776a.612.612 0 0 1-.613-.612v-2.172c-.682-.177-1.223-.35-1.658-.548L4.95 18.225a.612.612 0 0 1-.866 0l-2.31-2.308a.612.612 0 0 1 0-.866l1.558-1.557c-.198-.435-.37-.975-.547-1.657H.612A.612.612 0 0 1 0 11.224V8.776c0-.339.274-.613.612-.613h2.172c.177-.682.349-1.222.547-1.657L1.774 4.95a.612.612 0 0 1 0-.866l2.309-2.31c.24-.239.627-.239.866 0l1.557 1.557c.435-.198.976-.37 1.657-.546V.612c0-.338.274-.612.613-.612h2.448Zm-.612 1.224H9.388v2.04a.612.612 0 0 1-.469.595c-1.049.253-1.785.486-2.209.745a.612.612 0 0 1-.752-.09l-1.442-1.44-1.444 1.443 1.442 1.441c.2.2.238.51.09.752-.259.424-.492 1.159-.747 2.21a.612.612 0 0 1-.595.468H1.224v1.224h2.038c.283 0 .529.194.595.468.255 1.051.488 1.786.747 2.21a.612.612 0 0 1-.09.752l-1.442 1.442 1.444 1.443 1.441-1.442c.2-.2.511-.237.753-.09.424.26 1.159.493 2.21.748a.612.612 0 0 1 .468.595v2.038h1.224v-2.038c0-.283.194-.529.468-.595 1.051-.255 1.785-.488 2.209-.747a.612.612 0 0 1 .752.09l1.443 1.441 1.443-1.443-1.442-1.442a.612.612 0 0 1-.09-.752c.26-.424.493-1.159.748-2.21a.612.612 0 0 1 .595-.468h2.038V9.388h-2.039a.612.612 0 0 1-.595-.469c-.252-1.044-.487-1.785-.746-2.21a.612.612 0 0 1 .09-.752l1.44-1.44-1.443-1.444-1.442 1.442c-.2.2-.51.236-.752.09-.424-.26-1.16-.494-2.208-.746a.612.612 0 0 1-.469-.595v-2.04ZM10 5.714a4.286 4.286 0 1 1 0 8.572 4.286 4.286 0 0 1 0-8.572Zm0 1.225a3.062 3.062 0 1 0 0 6.123 3.062 3.062 0 0 0 0-6.123Z" />
  </svg>
);

SettingsNav.defaultProps = {
  width: 20,
  height: 20,
  color: colors.greyLight,
};

SettingsNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(SettingsNav);
