import React from 'react';
import { Field, Form } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import Checkbox from '../../../../../../components/Checkbox';
import HelpfulInfoList from '../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Tag from '../../../../../../components/Tag';
import { H2, Paragraph } from '../../../../../../components/Typography';

import styles from './RentCollection.module.scss';

const RentCollection = ({ onSubmit }) => {
  return (
    <Card className={styles.container}>
      <Form
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
          values: true,
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <H2 className="mb-16">How will you collect rent?</H2>

            <Field
              component={Checkbox}
              type="checkbox"
              name="ONLINE"
              id="ONLINE"
              label={
                <>
                  <Paragraph className="mb-4" weight="bold" size="large">
                    Payments through TurboTenant{' '}
                    <Tag small accent className={styles.tag}>
                      recommended
                    </Tag>
                  </Paragraph>
                  <HelpfulInfoList className={styles.list}>
                    <HelpfulInfoListItem className={styles.listItem}>
                      ACH/direct deposit
                    </HelpfulInfoListItem>
                    <HelpfulInfoListItem className={styles.listItem}>
                      Debit or credit card
                    </HelpfulInfoListItem>
                  </HelpfulInfoList>
                </>
              }
              wholeContainerClickable
              className={cx(styles.checkboxesContainer, {
                [styles.checkedCheckbox]: values.ONLINE,
              })}
            />

            <Field
              component={Checkbox}
              type="checkbox"
              name="OFFLINE"
              id="OFFLINE"
              label={
                <>
                  <Paragraph className="mb-4" weight="bold" size="large">
                    Methods outside of TurboTenant
                  </Paragraph>
                  <HelpfulInfoList className={styles.list}>
                    <HelpfulInfoListItem className={styles.listItem}>
                      Cash, check, payment apps, voucher, money order, etc.
                    </HelpfulInfoListItem>
                  </HelpfulInfoList>
                </>
              }
              wholeContainerClickable
              className={cx(styles.checkboxesContainer, {
                [styles.checkedCheckbox]: values.OFFLINE,
              })}
            />

            {(values.ONLINE || values.OFFLINE) && (
              <Button
                loading={submitting}
                className={styles.submit}
                type="submit"
              >
                next
              </Button>
            )}
          </form>
        )}
      </Form>
    </Card>
  );
};

RentCollection.propTypes = {
  onSubmit: PropTypes.func,
};

export default RentCollection;
