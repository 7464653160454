import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ApplicantNav = (props) => (
  <svg {...props} viewBox="0 0 16 20" fill={props.color}>
    <path d="M5.784 0a.57.57 0 0 1 .517.338l.92 2.015h8.208A.58.58 0 0 1 16 2.94v16.47a.58.58 0 0 1-.571.589H.57A.58.58 0 0 1 0 19.412V.588A.58.58 0 0 1 .571 0h5.213Zm-.363 1.176H1.143v17.648h13.714V3.529H6.858a.57.57 0 0 1-.517-.338l-.92-2.015ZM8 5.882c1.658 0 3.003 1.4 3.003 3.125 0 .68-.21 1.324-.581 1.85 1.597.83 2.435 2.217 2.435 4.397 0 .347-.27.628-.605.628H3.748a.617.617 0 0 1-.605-.628c0-2.18.837-3.567 2.435-4.397a3.202 3.202 0 0 1-.581-1.848C4.997 7.28 6.339 5.882 8 5.882ZM8 7.14c-.992 0-1.793.835-1.793 1.869 0 .627.297 1.2.788 1.547.413.293.326.95-.149 1.116-1.512.527-2.305 1.382-2.463 2.953h7.234c-.159-1.57-.952-2.426-2.464-2.953-.473-.166-.561-.823-.149-1.116.491-.348.79-.921.79-1.549C9.793 7.976 8.99 7.14 8 7.14Z" />
  </svg>
);

ApplicantNav.defaultProps = {
  width: 16,
  height: 20,
  color: colors.greyLight,
};

ApplicantNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ApplicantNav);
