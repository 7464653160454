import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Property = ({ color, ...rest }) => (
  <svg {...rest} viewBox="0 0 32 32" fill={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4852 5.4908L21.1694 8.90265L21.1695 8.2816C21.1695 7.88185 21.4655 7.55023 21.8534 7.4879L21.9858 7.47737H25.2507C25.7015 7.47737 26.0669 7.83744 26.0669 8.2816L26.0667 12.4693L29.0008 14.6053C29.6339 15.0664 29.3029 16.0563 28.5156 16.0563L26.0667 16.056V26.3906L28.5156 26.3915C28.9213 26.3915 29.2579 26.6832 29.3212 27.0653L29.3318 27.1958C29.3318 27.6399 28.9664 28 28.5156 28H3.48455C3.03376 28 2.66832 27.6399 2.66832 27.1958C2.66832 26.7516 3.03376 26.3915 3.48455 26.3915L5.93208 26.3906V16.056L3.48455 16.0563C2.69725 16.0563 2.36626 15.0664 2.99939 14.6053L15.5149 5.4908C15.8033 5.28082 16.1969 5.28082 16.4852 5.4908ZM24.4345 16.2007H7.5657V26.1367L9.19741 26.136L9.19816 19.1006C9.19816 18.4674 9.5636 17.9541 10.0144 17.9541H13.2793C13.7301 17.9541 14.0955 18.4674 14.0955 19.1006L14.0947 26.136L24.4345 26.1367V16.2007ZM12.4631 20.247H10.8306V25.5972H12.4631V20.247ZM21.9858 17.6642C22.4366 17.6642 22.802 18.0243 22.802 18.4684V21.6853C22.802 22.1295 22.4366 22.4896 21.9858 22.4896H16.5442C16.0934 22.4896 15.728 22.1295 15.728 21.6853V18.4684C15.728 18.0243 16.0934 17.6642 16.5442 17.6642H21.9858ZM21.1695 19.2727H17.3605V20.8811H21.1695V19.2727ZM16.0001 7.1376L5.96209 14.4479H26.0381L16.0001 7.1376ZM24.4345 9.08582H22.802L22.8014 10.0906L24.4334 11.28L24.4345 9.08582Z"
    />
  </svg>
);

Property.defaultProps = {
  color: colors.text,
};

Property.propTypes = {
  color: PropTypes.string,
};

export default memo(Property);
