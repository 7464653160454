import React from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import AcademyCourseDescription from '../../../../components/AcademyCourseDescription';
import VideoAndDescriptionHolder from '../../../../components/VideoAndDescriptionHolder';
import LoadAcademyModal, { useOpenAcademy } from '../LoadAcademyModal';

import FreeEducationSection from './FreeEducationSection/FreeEducationSection';
import Header from './Header';

import styles from './AcademyPage.module.scss';

const AcademyPage = () => {
  const history = useHistory();
  const [isAcademyLoadingForStudentHousing, openAcademyForStudentHousing] =
    useOpenAcademy('/course/invest-in-student-housing');
  const [isAcademyLoadingForTaxes, openAcademyForTaxes] = useOpenAcademy(
    '/course/10-expensive-tax-mistakes-to-avoid',
  );
  const [isAcademyLoadingForFairHousing, openAcademyFairHousing] =
    useOpenAcademy('/course/fair-housing-for-landlords');

  const [isTTOnboarding, openTTOnboarding] = useOpenAcademy(
    '/course/turbotenant-onboarding',
  );

  if (
    queryString.parse(history.location.search).skip === 'true' &&
    !(
      isAcademyLoadingForTaxes ||
      isAcademyLoadingForFairHousing ||
      isAcademyLoadingForStudentHousing
    )
  ) {
    history.replace('/owners/load-academy' + history.location.search);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.whiteBG}>
          <div className={styles.centerHelper}>
            <Header />
            <VideoAndDescriptionHolder
              openAcademy={openAcademyForStudentHousing}
            />
            <AcademyCourseDescription
              className={styles.courseDescription}
              openAcademy={openAcademyForTaxes}
              title="10 Expensive Tax Mistakes to Avoid"
              desc={`Deltrease Hart-Anderson, property investor and rental tax
                  strategist, teaches you how to avoid common mistakes and make
                  the tax system work for you and your rental business.`}
              videoUrl="https://www.youtube-nocookie.com/embed/slkzdr1zOcM?rel=0"
              videoHolderStyles={{
                backgroundImage:
                  'url(https://i.ytimg.com/vi/slkzdr1zOcM/maxresdefault.jpg)',
              }}
              actionLabel={'LEARN MORE '}
            />
            <AcademyCourseDescription
              className={styles.courseDescription}
              openAcademy={openTTOnboarding}
              title="TurboTenant Onboarding Course"
              desc={`This free step-by-step guide walks you through all of
                  TurboTenant’s robust features, from marketing vacancies and
                  screening to collecting rent and coordinating maintenance.`}
              videoUrl="https://www.youtube-nocookie.com/embed/7s4vPuV9qP0?rel=0"
              videoHolderStyles={{
                backgroundImage:
                  'url(https://i.ytimg.com/vi/7s4vPuV9qP0/maxresdefault.jpg)',
              }}
              actionLabel={'LEARN MORE '}
            />
            <AcademyCourseDescription
              className={styles.courseDescription}
              openAcademy={openAcademyFairHousing}
              title="Fair Housing for Landlords"
              desc={`Many fair housing violations are due to inadvertent mistakes
                that can bankrupt your rental business. Protect yourself and
                better serve your tenants with this comprehensive course.`}
              videoUrl="https://www.youtube-nocookie.com/embed/gPr9B5PNy64?rel=0"
              videoHolderStyles={{
                backgroundImage:
                  'url(https://i.ytimg.com/vi/gPr9B5PNy64/maxresdefault.jpg)',
              }}
              actionLabel={'LEARN MORE '}
            />
          </div>
        </div>
        <div className={styles.centerHelper}>
          <FreeEducationSection />
        </div>
      </div>
      <LoadAcademyModal
        open={
          isAcademyLoadingForFairHousing ||
          isAcademyLoadingForTaxes ||
          isTTOnboarding
        }
      />
    </>
  );
};

export default AcademyPage;
