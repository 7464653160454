const OnboardingProcessEnum = {
  MARKETING: 'MARKETING',
  RECEIVE_APPLICATION: 'RECEIVE_APPLICATION',
  LEASE: 'LEASE',
  SCREEN_TENANT: 'SCREEN_TENANT',
  RENT_PAYMENTS: 'RENT_PAYMENTS',
  ALL: 'ALL',
};

export default OnboardingProcessEnum;
