import React, { useEffect, useState } from 'react';
import queryString from 'query-string';

import LoadAcademyModal, { useOpenAcademy } from './LoadAcademyModal';

const AcademyPage = ({ history }) => {
  const { academyPath, academyReferral } = queryString.parse(
    history.location.search,
  );

  const [isAcademyLoading, openAcademy] = useOpenAcademy(
    academyPath || '',
    academyReferral,
  );
  const [gettingAcademyUrl, setGettingAcademyUrl] = useState(false);

  useEffect(() => {
    openAcademy();
  }, []);

  useEffect(() => {
    if (isAcademyLoading) {
      setGettingAcademyUrl(true);
    } else if (gettingAcademyUrl) {
      // not loading anymore because of some error
      history.replace('/owners/academy');
    }
  }, [isAcademyLoading]);

  return (
    <>
      <LoadAcademyModal open={isAcademyLoading} />
    </>
  );
};

export default AcademyPage;
