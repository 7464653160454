import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const PropertyNav = (props) => (
  <svg {...props} viewBox="0 0 22 19" fill={props.color}>
    <path d="m11.4.132 3.864 2.859v-.52c0-.338.25-.618.575-.666l.1-.008h2.693c.338 0 .618.25.666.575l.007.1V5.98l2.42 1.791c.523.387.25 1.216-.4 1.216h-2.02v8.663h2.02c.338 0 .618.25.666.575l.008.1c0 .338-.25.619-.574.667l-.1.007H.675a.674.674 0 0 1-.1-1.34l.1-.008 2.019-.001V8.988H.674c-.649 0-.922-.83-.4-1.216L10.6.132a.673.673 0 0 1 .8 0Zm6.558 8.977H4.042v8.33l1.346-.001V11.54c0-.53.302-.96.674-.96h2.693c.372 0 .674.43.674.96l-.001 5.898h8.53V9.11Zm-9.876 3.392H6.735v4.485h1.347V12.5Zm7.856-2.165c.372 0 .674.302.674.674v2.697a.674.674 0 0 1-.674.674h-4.49a.674.674 0 0 1-.672-.674V11.01c0-.372.301-.674.673-.674h4.49Zm-.673 1.348h-3.143v1.349h3.143v-1.349ZM11 1.512 2.719 7.64h.636a.63.63 0 0 1 .013 0h15.913L11 1.512Zm6.958 1.633h-1.346l-.001.843 1.347.997v-1.84Z" />
  </svg>
);

PropertyNav.defaultProps = {
  width: 22,
  height: 19,
  color: colors.greyLight,
};

PropertyNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(PropertyNav);
