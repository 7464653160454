import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Academy = (props) => (
  <svg {...props} viewBox="0 0 20 17" fill={props.color}>
    <path
      fillRule="evenodd"
      d="m10.277.071 9.41 5.138a.616.616 0 0 1 0 1.073l-2.73 1.49v5.707l-.135.168c-.149.186-.397.441-.749.733.106.235.165.497.165.774 0 1.02-.804 1.846-1.795 1.846-.58 0-1.095-.282-1.423-.72a8.457 8.457 0 0 1-2.973.517c-1.97 0-3.659-.595-5.061-1.586-.846-.599-1.414-1.203-1.71-1.625l-.112-.16-.001-5.588-2.85-1.556a.617.617 0 0 1-.082-1.019l.083-.054L9.723.07a.577.577 0 0 1 .554 0Zm4.166 14.452a.622.622 0 0 0-.614.63c0 .349.275.631.614.631a.622.622 0 0 0 .613-.63.622.622 0 0 0-.613-.63Zm1.332-6.106-.713.389v3.329a.6.6 0 0 1-.59.608.6.6 0 0 1-.591-.608l-.001-2.683-3.603 1.968a.577.577 0 0 1-.465.04l-.09-.04-5.376-2.936v4.533l.093.11.132.143c.3.319.661.64 1.085.939 1.214.858 2.674 1.373 4.391 1.373.952 0 1.817-.16 2.602-.455l-.002.027c0-1.02.804-1.846 1.796-1.846.288 0 .56.07.802.194.115-.093.22-.184.317-.273l.213-.207V8.417ZM10 1.296 1.852 5.744 10 10.194l3.414-1.865L9.719 6.28a.616.616 0 0 1-.273-.748l.034-.075a.583.583 0 0 1 .8-.247l4.388 2.434 3.48-1.899L10 1.295Z"
    />
  </svg>
);

Academy.defaultProps = {
  width: 20,
  height: 17,
  color: colors.greyLight,
};

Academy.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Academy);
