import React from 'react';
import cx from 'classnames';
import kebabCase from 'lodash.kebabcase';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import Label from '../Label';
import PlainRadioButton from '../PlainRadioButton';

import RadioGroupItemDescription from './components/RadioGroupItemDescription';

import styles from './RadioGroup.module.scss';

const RadioGroup = ({
  className,
  options,
  hint,
  input,
  radioProps,
  labelProps,
  style,
  meta,
  label,
  id,
  disabled,
  vertical,
  canUnselect,
  radiosContainerClassName,
  radioRowClassName,
  radioSelectedClassName,
  inline = true,
  onRadioChange,
}) => {
  const error = getFieldError(meta);
  return (
    <div
      className={cx(
        styles.wrap,
        {
          [styles.inline]: inline,
          [styles.block]: !inline,
        },
        className,
      )}
      style={style}
    >
      {label && (
        <Label
          {...labelProps}
          className={labelProps.className}
          htmlFor={id}
          hint={hint}
          hintClassName={labelProps.hintClassName}
        >
          {label}
        </Label>
      )}
      <div
        className={cx(
          styles.container,
          {
            [styles.horizontal]: !vertical,
            [styles.block]: !inline,
          },
          radiosContainerClassName,
        )}
      >
        {options.map(
          ({
            value,
            label,
            description,
            'data-tooltip-id': dataTooltipId,
            ...restProps
          }) => (
            <label
              className={cx(
                styles.radioRow,
                {
                  [styles.horizontalRow]: !vertical,
                },
                radioRowClassName,
                {
                  [radioSelectedClassName]: input.value === value,
                },
              )}
              key={`radio-${value}`}
              data-tooltip-id={dataTooltipId}
            >
              <PlainRadioButton
                checked={input.value === value}
                value={value}
                name={input.name}
                disabled={disabled}
                className={styles.radio}
                onClick={(e) => {
                  if (e.target.value === input.value && canUnselect) {
                    input.onChange(null);
                  }
                }}
                onChange={() => {
                  if (onRadioChange) {
                    onRadioChange(value);
                  }
                  input.onChange(value);
                }}
                data-qa={kebabCase(`radio-${id}-input-option-${value}`)}
                {...radioProps}
                {...restProps}
              />
              <div>
                <span
                  className={cx(styles.radioLabel, {
                    [styles.disabled]: disabled,
                  })}
                >
                  {label}
                </span>
                <RadioGroupItemDescription description={description} />
              </div>
            </label>
          ),
        )}
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

RadioGroup.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ).isRequired,
  input: PropTypes.object,
  radioProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  labelProps: PropTypes.object,
  vertical: PropTypes.bool,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

RadioGroup.defaultProps = {
  radioProps: {},
  meta: {},
  input: {
    value: '',
  },
  labelProps: {},
};

export default RadioGroup;
