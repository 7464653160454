import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const LogoutNav = (props) => (
  <svg {...props} viewBox="0 0 19 20" fill={props.color}>
    <path d="M12.293.09a.67.67 0 0 1 .597.66v3.54c-.004.062-.002.078-.015.139a.667.667 0 0 1-1.315-.069c-.003-.023-.003-.046-.004-.07V1.416H1.333v17.257h10.223V16.7c.001-.023.001-.047.004-.07a.665.665 0 0 1 .934-.537.67.67 0 0 1 .381.469c.013.06.011.076.015.138v2.637a.776.776 0 0 1-.033.205.67.67 0 0 1-.634.459H.667a.67.67 0 0 1-.634-.459.776.776 0 0 1-.033-.205V.751A.776.776 0 0 1 .033.546.67.67 0 0 1 .667.087c3.875 0 7.756-.198 11.626.004ZM3.556 10.47l.004-.048a.664.664 0 0 1 .038-.175.724.724 0 0 1 .12-.203c1.44-1.538 2.985-2.974 4.478-4.46a.787.787 0 0 1 .11-.089.708.708 0 0 1 .314-.103c.048-.002.047-.002.095 0a.708.708 0 0 1 .313.103c.26.167.372.502.264.79a.712.712 0 0 1-.153.238L5.825 9.82l.751.001h.429l5.397.001h3.473l.614.001h1.907a.67.67 0 0 1 .535.365.668.668 0 0 1-.3.89.727.727 0 0 1-.297.07H5.84l3.3 3.285.032.035a.664.664 0 0 1-.94.936c-1.527-1.415-2.953-2.934-4.427-4.404a.667.667 0 0 1-.247-.522v-.01Z" />
  </svg>
);

LogoutNav.defaultProps = {
  width: 19,
  height: 20,
  color: colors.greyLight,
};

LogoutNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(LogoutNav);
