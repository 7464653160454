import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { parse, stringify } from 'query-string';

import { useUserProfile } from '../../../../../core/TTgraphql';
import rentPaymentsPreferenceMutation from '../../../../../graphql/mutations/payments/setRentPaymentsPreference.graphql';
import leasesQuery from '../../../../../graphql/queries/leasesQuery.graphql';
import rentPaymentsQuery from '../../../../../graphql/queries/rentPaymentsQuery.graphql';
import { segmentTracking } from '../../../../../services/utilities';

import BankWrapper from './BankWrapper';
import RentCollection from './RentCollection';

const CollectionWrapper = (props) => {
  const {
    onNext,
    onboarding,
    overrideBackButtonClick,
    bankStep,
    location,
    history,
  } = props;

  const { user } = useUserProfile({}, false);
  const [showBankStep, setShowBankStep] = useState(bankStep);
  const queryParams = parse(location.search, { parseBooleans: true });

  const { manually, ...restQueryParams } = queryParams;
  const [setRentPaymentsPreference] = useMutation(
    rentPaymentsPreferenceMutation,
    {
      refetchQueries: [{ query: rentPaymentsQuery }, { query: leasesQuery }],
      awaitRefetchQueries: true,
    },
  );

  const isCustomer = user.is_customer;

  useEffect(() => {
    if (manually) {
      overrideBackButtonClick(() => {
        const params = restQueryParams ? '?' + stringify(restQueryParams) : '';
        history.replace(location.pathname + params);
      });
    } else if (showBankStep) {
      overrideBackButtonClick(() => {
        setShowBankStep(false);
      });
    } else {
      overrideBackButtonClick(null);
    }
    return () => {
      overrideBackButtonClick(null);
    };
  }, [showBankStep, manually]);

  const onBankNext = async () => {
    if (onboarding) {
      segmentTracking('bank_step submit', {
        location: 'rp onboarding bank step',
      });
    } else {
      if (isCustomer) {
        segmentTracking('bank_step submit', {
          location: 'rp existing bank step lr',
        });
      } else {
        segmentTracking('bank_step submit', {
          location: 'rp existing bank step hr',
        });
      }
    }
    onNext();
  };

  const onRentCollectionNext = async (data) => {
    const online = data.ONLINE;
    const offline = data.OFFLINE;

    let rent_collection_answer;

    if (online && offline) {
      rent_collection_answer = 'both';
    } else if (online) {
      rent_collection_answer = 'Online';
    } else {
      rent_collection_answer = 'Offline';
    }

    segmentTracking('next clicked', {
      location: onboarding
        ? 'rp onboarding collection'
        : 'rp existing collection',
      rent_collection_answer,
    });
    if (online) {
      await setRentPaymentsPreference({
        variables: { preference: 'ONLINE' },
      });
      setShowBankStep(true);
    } else if (offline) {
      await setRentPaymentsPreference({
        variables: { preference: 'OFFLINE' },
      });
      onNext();
    }
  };

  if (showBankStep) {
    return <BankWrapper {...props} onNext={onBankNext} hideCancel />;
  }

  return <RentCollection onSubmit={onRentCollectionNext} />;
};

export default CollectionWrapper;
