import { useMemo } from 'react';

import { useUserProfile } from '../TTgraphql';

const userReiHubPricing = (isQuarterlyExperiment) => {
  const property = isQuarterlyExperiment
    ? 'reihub_quarterly_pricings'
    : 'reihub_yearly_pricings';

  const { user } = useUserProfile();
  const map = useMemo(
    () =>
      user?.[property]?.reduce(
        (acc, pricing) => ({
          ...acc,
          [pricing.maxUnits]: pricing,
        }),
        {},
      ),
    [user, property],
  );

  return map;
};

export default userReiHubPricing;
