import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import cx from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import FinalFormError from '../../../components/FinalFormError';
import Input from '../../../components/Input';
import LoadingScreen from '../../../components/LoadingScreen';
import OptionalCard from '../../../components/OptionalCard';
import { useInfoToast } from '../../../components/Toast';
import { Paragraph } from '../../../components/Typography';
import { useUserProfile } from '../../../core/TTgraphql';
import discountPrice from '../../../helpers/discount-price';
import { getUserActiveCard } from '../../../helpers/getUserActiveCard';
import queryState from '../../../helpers/query-state';
import toCurrency from '../../../helpers/toCurrency';
import SecurityIcon from '../../../icons/Security';
import { useConfig } from '../../../providers/ConfigProvider';
import composeValidators from '../../../validators/composeValidators';
import fieldRequired from '../../../validators/fieldRequired';
import fieldRequiredShort from '../../../validators/fieldRequiredShort';
import isZip from '../../../validators/isZip';
import { useUpdatePaymentMethod } from '../../payments/usePayments';

import CardNumberElementWithIcon from './CardNumberElementWithIcon';
import PaymentCardIcon from './PaymentCardIcon';
import PaymentCardsList from './PaymentCardsList';
import PromoCodeField from './PromoCodeField';
import StripeBaseElement from './StripeBaseElement';
import StripeElementsWrapper from './StripeElementsWrapper';
import StripePaymentRequestButton from './StripePaymentRequestButton';

import styles from './StripeElementsComponent.module.scss';

const getBrand = (brand) => {
  if (brand === 'American Express') {
    return 'Amex';
  }
  if (brand === 'MasterCard') {
    return 'Mastercard';
  }
  if (!brand) {
    return 'Card';
  }
  return brand;
};

const useActiveCard = ({ user, loading }) => {
  const [activeCard, setActiveCard] = useState();

  useEffect(() => {
    if (loading) {
      return;
    }
    setActiveCard(getUserActiveCard(user));
  }, [loading, JSON.stringify(user?.payment_methods)]);

  return activeCard;
};

const StripeElementsComponent = ({
  title,
  description,
  amount,
  slashedAmount,
  onToken,
  history,
  amountLabel,
  buttonLabel,
  className,
  noCardBorder,
  isMobileApp,
  onPayClicked,
  footerNote,
  enablePromo,
  showPaymentRequestButton = false,
  withCard = true,
  isRentPaymentsPaymentMethod,
  isBillingPaymentMethod,
  showDefaultApplicationFee,
  showSuccessToast = true,
  additionalPaymentInfo,
  supportedStripeProductIds,
  autoRenewTermsLink = null,
  autoRenewPeriod,
  fullWidth = false,
  onFinishLoading,
  polling = true,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { user, loading: userLoading } = useUserProfile({ polling });
  const { IS_RENTER, DEFAULT_APPLICATION_FEE } = useConfig();
  const isSubscribingToPremium =
    title === 'Subscribe to Premium' ||
    title === 'Subscribe to Vacancy Pro' ||
    title === 'Subscribe to Manage Pro' ||
    title === 'Subscribe to Premium + VIP Support' ||
    title === 'Subscribe to Vacancy Pro + VIP Support' ||
    title === 'Subscribe to Manage Pro + VIP Support';

  const isSubscrbingToAnnualReiHub =
    (title === 'Subscribe to REI Hub' ||
      title === 'Rental Accounting Subscription') &&
    slashedAmount;

  const [changeCard, setChangeCard] = IS_RENTER
    ? useState(amount ? null : true)
    : queryState(history, 'change-card');

  const shouldShowAutoRenewTermsAcceptance =
    isSubscribingToPremium || autoRenewTermsLink || isSubscrbingToAnnualReiHub;

  const showInfoMessage = useInfoToast();
  const [updatePaymentMethod] = useUpdatePaymentMethod();
  const [formValidation, setFormValidation] = useState({
    cardCvc: false,
    cardExpiry: false,
    cardNumber: false,
    zipCode: false,
    ...(shouldShowAutoRenewTermsAcceptance &&
      !changeCard && {
        understandAutoRenew: false,
      }),
  });

  const [coupon, setCoupon] = useState(null);
  const [stripePaymentBtnAvailable, setStripePaymentBtnAvailable] =
    useState(false);
  const activeCard = useActiveCard({ loading: userLoading, user }); // Because of useUserProfile polling we had to use cached activeCard to avoid showing on "Card on file" when it changes states

  useEffect(() => {
    // Fix odd glitch on phones causing the screen to scroll down in some instances
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!userLoading && user) onFinishLoading?.();
  }, [userLoading, user]);

  const newAmount = coupon
    ? discountPrice(
        amount,
        coupon,
        supportedStripeProductIds,
        user.premium_price_signup,
      )
    : amount;

  const formHasError = Object.entries(formValidation).some(([key, value]) => {
    if (changeCard && shouldShowAutoRenewTermsAcceptance) {
      // Disregard terms acceptance during card update since we dont even show it
      if (key === 'understandAutoRenew') {
        return false;
      }
    }
    return !value;
  });

  function handleOnValid(elementKey, isValid) {
    setFormValidation((prevFormValidation) => ({
      ...prevFormValidation,
      [elementKey]: isValid,
    }));
  }

  function getCtaButtonLabel() {
    if (changeCard) return 'Save Card';
    if (coupon) return `PAY ${toCurrency(newAmount)}`;
    return buttonLabel || `PAY ${toCurrency(newAmount)}`;
  }

  const submit = useCallback(
    async ({ cardHolder, zip, promoCode }) => {
      if (onPayClicked) {
        onPayClicked();
      }

      if (activeCard && !changeCard) {
        await onToken(null, { promoCode });
        return;
      }

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return {
          error: 'An error occured. Please try again.',
        };
      }

      const card = elements.getElement(CardNumberElement);

      if (formHasError) {
        elements.getElement('card').focus();
        return {
          error:
            'Some fields are invalid. Please check the fields marked in red.',
        };
      }

      const result = await stripe.createToken(card, {
        currency: 'usd',
        address_zip: zip,
        name: cardHolder,
      });

      if (result.error) {
        const error = get(result, 'error.message', 'An error occured');
        return {
          error,
        };
      }
      if (changeCard) {
        try {
          const res = await updatePaymentMethod({
            variables: {
              token: result.token.id,
              zipCode: zip,
              cardHolder,
              isRentPaymentsPaymentMethod,
              isBillingPaymentMethod,
            },
          });
          if (res.data.updatePaymentMethod.ok) {
            if (showSuccessToast) {
              showInfoMessage('You added a card on file.');
            }
            setChangeCard(null);
          }
        } catch (e) {
          const message = get(
            e,
            'graphQLErrors[0].message',
            'An error occurred',
          );
          return {
            error: message,
          };
        }
        // if we are only changing the card on file, no need to stay in the payment page
        if (!amount) await onToken(null, {});
        return;
      }
      await onToken(result.token.id, { zipCode: zip, cardHolder, promoCode });
    },
    [changeCard, activeCard, formHasError, onToken, onPayClicked, stripe],
  );

  // when we don't have payment method ui is different
  // so to prevent ui flickering
  // we show empty loading screen
  if (userLoading) {
    return <LoadingScreen loading />;
  }

  return (
    <OptionalCard
      withCard={withCard}
      className={cx(styles.baseCard, className, {
        [styles.cardNoBorder]: noCardBorder,
        [styles.baseCard_premium]: isSubscribingToPremium,
        [styles.mobile_version]: isMobileApp,
      })}
    >
      <Form
        onSubmit={submit}
        subscription={{
          submitting: true,
          pristine: true,
          // there is a bug in this form so that's why
          // we need to subscribe to values
          values: true,
        }}
      >
        {({ handleSubmit, submitting, pristine, values, form }) => {
          let disabled = false;
          if (!activeCard || changeCard) {
            disabled = !stripe || formHasError || !elements || pristine;
          } else if (shouldShowAutoRenewTermsAcceptance) {
            disabled = !formValidation.understandAutoRenew;
          }

          return (
            <form
              onSubmit={handleSubmit}
              id="stripe-elements-form"
              className={cx({
                [styles.fullWidth]: fullWidth,
              })}
            >
              {!isMobileApp && (
                <>
                  <h1
                    className={cx(styles.title, {
                      [styles.smallMargin]: !!description,
                    })}
                    id="stripe-elements-title"
                  >
                    {title || (changeCard && 'Update Card on File')}
                  </h1>
                  {description && (
                    <Paragraph className={styles.description}>
                      {description}
                    </Paragraph>
                  )}
                </>
              )}
              <FormSpy
                subscription={{ values: true }}
                onChange={({ values }) => {
                  setFormValidation({
                    ...formValidation,
                    zipCode:
                      isZip(values.zip) === undefined &&
                      values?.zip?.length > 0,
                    ...(shouldShowAutoRenewTermsAcceptance && {
                      understandAutoRenew: values.understandAutoRenew === true,
                    }),
                  });
                }}
              />
              <FinalFormError />
              <div className={cx(styles.group, styles.priceContainer)}>
                {!changeCard && newAmount ? (
                  <div className={styles.fieldGroup}>
                    <div className={styles.fieldLabel}>Payment Amount</div>
                    <div className={styles.amount}>
                      {coupon || slashedAmount ? (
                        <span className={styles.oldPrice}>
                          {toCurrency(coupon ? amount : slashedAmount)}
                        </span>
                      ) : null}
                      <span>{toCurrency(newAmount)}</span>
                      {amountLabel && (
                        <span className={styles.amountLabel}>
                          &nbsp;/{amountLabel}
                        </span>
                      )}

                      {showDefaultApplicationFee ? (
                        <span className={styles.originalAmount}>
                          {toCurrency(DEFAULT_APPLICATION_FEE)}
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {(!showPaymentRequestButton || !stripePaymentBtnAvailable) && (
                  <PaymentCardsList />
                )}
              </div>

              {additionalPaymentInfo ? (
                <span
                  className={cx(styles.group, styles.additionalPaymentInfo)}
                >
                  {additionalPaymentInfo}
                </span>
              ) : null}

              {showPaymentRequestButton && (
                <div
                  className={cx(styles.stripeButtonContainer, {
                    [styles.stripeButtonNotAvailable]:
                      !stripePaymentBtnAvailable,
                  })}
                >
                  <StripePaymentRequestButton
                    amount={newAmount}
                    description={amountLabel || 'Screening Fee'}
                    onToken={onToken}
                    showOr
                    onMakePaymentAvailable={setStripePaymentBtnAvailable}
                  />

                  {stripePaymentBtnAvailable && <PaymentCardsList />}
                </div>
              )}

              {activeCard && !changeCard ? (
                <>
                  <div className={styles.group}>
                    <div className={styles.fieldGroup}>
                      <div className={styles.fieldLabel}>CARD ON FILE</div>
                      <div
                        className={styles.existingCardWrap}
                        id="card-on-file"
                      >
                        <PaymentCardIcon
                          brand={activeCard.brand}
                          className={styles.existingCard}
                        />
                        {`${getBrand(activeCard.brand)} ending in ${
                          activeCard.last4
                        }`}
                        &nbsp;
                        {'-'}
                        &nbsp;
                        <button
                          className={styles.linkButton}
                          onClick={() => setChangeCard(true)}
                          type="button"
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.fieldsGroup}>
                    <Field
                      id="card_holder"
                      name="cardHolder"
                      type="text"
                      component={Input}
                      label="Card Holder Name"
                      validate={fieldRequired}
                      className={cx(styles.cardHolder, {
                        [styles.fullWidth]: isMobileApp,
                      })}
                    />
                  </div>
                  <div className={styles.fieldsGroup}>
                    <StripeBaseElement
                      id="stripe-card"
                      label="Card Number"
                      component={CardNumberElementWithIcon}
                      containerStyles={cx(styles.card, {
                        [styles.fullWidth]: isMobileApp,
                      })}
                      baseStyles={{
                        fontSize: '14px',
                      }}
                      onValid={handleOnValid}
                    />
                  </div>
                  <div
                    className={cx(styles.fieldsGroup, styles.lastFieldGroup, {
                      [styles.fullWidth]: isMobileApp,
                    })}
                  >
                    <StripeBaseElement
                      id="stripe-expiration"
                      label="Expiration"
                      component={CardExpiryElement}
                      onValid={handleOnValid}
                      containerStyles={cx(styles.expiration, {
                        [styles.evenSpace]: isMobileApp,
                      })}
                    />
                    <StripeBaseElement
                      id="stripe-cvc"
                      label="CVC"
                      component={CardCvcElement}
                      onValid={handleOnValid}
                      containerStyles={cx(styles.cvc, {
                        [styles.evenSpace]: isMobileApp,
                      })}
                    />
                    <Field
                      component={Input}
                      label="Zip Code"
                      type="tel"
                      id="address_zip"
                      name="zip"
                      validate={composeValidators(fieldRequiredShort, isZip)}
                      parse={(value) => (value ? value.substring(0, 5) : '')}
                      className={cx(styles.zip, {
                        [styles.evenSpace]: isMobileApp,
                      })}
                    />
                  </div>
                </>
              )}

              {!changeCard && enablePromo ? (
                <div className={styles.promoCodeContainer}>
                  <Field
                    component={PromoCodeField}
                    id="promoCode"
                    name="promoCode"
                    // ugly hack used to trigger
                    // rerender so it trigger the validation
                    // when promoCode change
                    key={coupon ? 'no-validation' : 'validation'}
                    validate={(val) => {
                      if (coupon || !val) {
                        return;
                      }
                      return 'Apply the promo code.';
                    }}
                    coupon={coupon}
                    setCoupon={setCoupon}
                  />
                </div>
              ) : null}

              {shouldShowAutoRenewTermsAcceptance && !changeCard && (
                <div className={styles.subscriptionCheckbox}>
                  <Field
                    id={`understandAutoRenew`}
                    name={`understandAutoRenew`}
                    component={Checkbox}
                    className={styles.checkbox}
                    type="checkbox"
                    initialValue={false}
                    data-qa="understand-auto-renew"
                  />
                  <div>
                    <span
                      onClick={() =>
                        form.change(
                          'understandAutoRenew',
                          !values.understandAutoRenew,
                        )
                      }
                    >
                      I understand this auto-renews {autoRenewPeriod}
                      {'. '}
                    </span>
                    <a
                      href={
                        autoRenewTermsLink ||
                        'https://www.turbotenant.com/terms-of-use/#premium-plan-terms'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      See Terms
                    </a>
                    .
                  </div>
                </div>
              )}
              <div className={styles.action} key={newAmount}>
                <Button
                  type="submit"
                  id="payment-action"
                  disabled={disabled}
                  loading={submitting}
                  className={styles.actionBtn}
                >
                  {getCtaButtonLabel()}
                </Button>
              </div>

              <p className={cx(styles.info)}>
                <SecurityIcon style={{ marginRight: '9px' }} /> We keep your
                payment info secure.
              </p>

              {footerNote && <p className={styles.footerNote}>{footerNote}</p>}
            </form>
          );
        }}
      </Form>
    </OptionalCard>
  );
};

const StripeElementsComponentWrapped = ({ stripeKey, ...restProps }) => {
  return (
    <StripeElementsWrapper stripeKey={stripeKey}>
      <StripeElementsComponent {...restProps} />
    </StripeElementsWrapper>
  );
};

StripeElementsComponentWrapped.defaultProps = {
  amountLabel: '',
  footerNote: null,
  autoRenewTermsLink: null,
  autoRenewPeriod: 'yearly',
  polling: true,
};

StripeElementsComponent.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  slashedAmount: PropTypes.number,
  onToken: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  amountLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  className: PropTypes.string,
  noCardBorder: PropTypes.bool,
  isMobileApp: PropTypes.bool,
  onPayClicked: PropTypes.func,
  onFinishLoading: PropTypes.func,
  footerNote: PropTypes.string,
  enablePromo: PropTypes.bool,
  showPaymentRequestButton: PropTypes.bool,
  withCard: PropTypes.bool,
  isRentPaymentsPaymentMethod: PropTypes.bool,
  isBillingPaymentMethod: PropTypes.bool,
  showDefaultApplicationFee: PropTypes.bool,
  showSuccessToast: PropTypes.bool,
  additionalPaymentInfo: PropTypes.string,
  supportedStripeProductIds: PropTypes.arrayOf(PropTypes.string),
  autoRenewTermsLink: PropTypes.string,
  autoRenewPeriod: PropTypes.oneOf(['yearly', 'monthly']),
  fullWidth: PropTypes.bool,
  polling: PropTypes.bool,
  description: PropTypes.string,
};

export default compose(withRouter)(StripeElementsComponentWrapped);
