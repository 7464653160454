import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import PageWrapper from '../../../../components/PageWrapper';
import componentLoader from '../../../../helpers/component-loader';

const AddBankInfoManually = componentLoader(() =>
  import('./AddBankInfoManually'),
);

const ManageBankAccount = componentLoader(() => import('./ManageBankAccount'));

const BankAccountRoutes = ({ match }) => {
  const me = match.url;
  return (
    <Switch>
      <Route
        path={`${me}/add-bank-info-manually`}
        render={(props) => (
          <PageWrapper>
            <AddBankInfoManually {...props} />
          </PageWrapper>
        )}
      />
      <Route
        path={`${me}/replace-bank-info-manually/:bankAccountIDToReplace`}
        render={(props) => (
          <PageWrapper>
            <AddBankInfoManually {...props} />
          </PageWrapper>
        )}
      />
      <Route
        path={`${me}/add-bank-account`}
        render={(props) => (
          <PageWrapper>
            <ManageBankAccount {...props} />
          </PageWrapper>
        )}
      />
      <Route
        path={`${me}/replace-bank-account/:bankAccountIDToReplace`}
        render={(props) => (
          <PageWrapper>
            <ManageBankAccount {...props} />
          </PageWrapper>
        )}
      />
    </Switch>
  );
};

BankAccountRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default BankAccountRoutes;
