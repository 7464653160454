import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const LeadsNav = (props) => (
  <svg {...props} viewBox="0 0 22 19" fill={props.color}>
    <path d="M0 9.713V6.945c0-.308.255-.558.57-.558h4.563c.315 0 .57.25.57.558v2.769c0 .951-.257 1.622-.912 1.853v3.68a.565.565 0 0 1-.57.56H1.483a.565.565 0 0 1-.57-.56v-3.68C.257 11.337 0 10.665 0 9.713Zm1.14 0c0 .655.092.825.343.825.315 0 .57.25.57.559v3.592H3.65v-3.592c0-.309.256-.56.57-.56.251 0 .342-.17.343-.824V7.504H1.141v2.209Zm15.156 0V6.945c0-.308.256-.558.57-.558h4.564c.315 0 .57.25.57.558v2.769c-.001.951-.258 1.622-.913 1.853v3.68a.565.565 0 0 1-.57.56h-2.738a.565.565 0 0 1-.57-.56v-3.68c-.656-.23-.913-.902-.913-1.854Zm1.141 0c0 .655.09.825.342.825.315 0 .57.25.57.559v3.592h1.598v-3.592c0-.309.255-.56.57-.56.25 0 .341-.17.342-.824V7.504h-3.422v2.209Zm-9.126 1.654c0 .66.578 1.21 1.292 1.21.315 0 .57.25.57.558v4.747h1.653v-4.747c0-.308.256-.558.57-.558.714 0 1.293-.55 1.293-1.21V7.504H8.31v3.863Zm-1.14 0V6.945c0-.308.255-.558.57-.558h6.518c.315 0 .57.25.57.558v4.422c0 1.096-.798 2.016-1.862 2.262v4.812a.565.565 0 0 1-.57.559H9.603a.565.565 0 0 1-.57-.559V13.63c-1.064-.246-1.863-1.165-1.863-2.262ZM3.258 3.752a.726.726 0 0 0-.733-.718.726.726 0 0 0-.733.718c0 .397.328.719.733.719a.726.726 0 0 0 .733-.719Zm1.141 0c0 1.014-.84 1.836-1.874 1.836-1.035 0-1.874-.822-1.874-1.836 0-1.014.84-1.836 1.874-1.836 1.035 0 1.874.822 1.874 1.836Zm15.156 0a.726.726 0 0 0-.734-.718.726.726 0 0 0-.733.718c0 .397.329.719.733.719a.726.726 0 0 0 .734-.719Zm1.14 0c0 1.014-.84 1.836-1.874 1.836-1.034 0-1.874-.822-1.874-1.836 0-1.014.84-1.836 1.874-1.836 1.035 0 1.874.822 1.874 1.836Zm-8.31-.958c0-.926-.767-1.676-1.712-1.676s-1.711.75-1.711 1.676c0 .926.766 1.677 1.711 1.677.945 0 1.711-.75 1.711-1.677Zm1.14 0c0 1.544-1.277 2.794-2.852 2.794s-2.852-1.25-2.852-2.794C7.822 1.251 9.1 0 10.674 0s2.852 1.25 2.852 2.794Z" />
  </svg>
);

LeadsNav.defaultProps = {
  width: 22,
  height: 19,
  color: colors.greyLight,
};

LeadsNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(LeadsNav);
