const getDeviceDetails = () => {
  const userAgent = navigator.userAgent;
  const deviceType =
    /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
      ? 'iOS Device'
      : /android/i.test(userAgent)
      ? 'Android Device'
      : 'Desktop';

  let operatingSystem = 'Unknown OS';
  if (userAgent.indexOf('Win') != -1) operatingSystem = 'Windows';
  if (userAgent.indexOf('Mac') != -1) operatingSystem = 'MacOS';
  if (userAgent.indexOf('X11') != -1) operatingSystem = 'UNIX';
  if (userAgent.indexOf('Linux') != -1) operatingSystem = 'Linux';

  return { deviceType, operatingSystem };
};

export default getDeviceDetails;
