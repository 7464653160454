import React from 'react';
import { FocusOn } from 'react-focus-on';
import OutsideClickHandler from 'react-outside-click-handler';
import useWindowSize from '@rooks/use-window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';

import breakpoints from '../../constants/media-breakpoints';
import CloseModal from '../../icons/CloseModal';
import Card from '../Card';
import IconButton from '../IconButton';

import Content from './components/Content';
import Footer from './components/Footer';
import Header from './components/Header';
import HeaderBanner from './components/Header/components/Banner';
import Title from './components/Header/components/Title';
import { ModalContextProvider } from './context';
import { ModalPlug } from './ModalProvider';

import styles from './Modal.module.scss';

const MOBILE_BREAKPOINT = breakpoints.mobileBreakpoint;

const Modal = React.forwardRef(
  (
    {
      onClose,
      children,
      style,
      className,
      containerClassName,
      open,
      overlayColor,
      fullScreen,
      mobileFullScreen,
      id,
      disableOutsideClick,
      showCloseIcon,
      autoFocus,
      disableCloseIcon,
      closeIconClassName,
      size,
      banner,
      bannerClassName,
      ...restProps
    },
    ref,
  ) => {
    const { innerWidth } = useWindowSize();
    const isSmallScreen = innerWidth <= MOBILE_BREAKPOINT;
    if (!open) {
      return null;
    }
    return (
      <ModalPlug id={id}>
        <ModalContextProvider hasBanner={banner}>
          <FocusOn
            className={cx(styles.wrap, containerClassName, {
              [styles.mobileFullscreen]: mobileFullScreen,
              [styles.fullscreen]: fullScreen,
            })}
            role="dialog"
            tabIndex="-1"
            aria-labelledby="modal"
            aria-live="assertive"
            style={{
              backgroundColor: overlayColor,
            }}
            onEscapeKey={onClose}
            autoFocus={autoFocus}
          >
            {/* NOTE: This is a div that wraps the <Card /> component * and is
          used for handling outside click events. We don't need it to * take up
          any space other than the space it's children would take so we * use
          `display: contents` to achieve that. Due to a known issue * on some
          iOs Safari browsers with `display: contents;` * we force it to reset
          it to display: block;` on mobile to avoid those issues */}
            <OutsideClickHandler
              onOutsideClick={onClose}
              disabled={disableOutsideClick}
              display={isSmallScreen ? 'block' : 'contents'}
            >
              <Card
                ref={ref}
                className={cx(
                  styles.modal,
                  className,
                  size ? styles[`size-${size}`] : '',
                )}
                style={style}
                id={id}
                {...restProps}
              >
                {banner ? (
                  <HeaderBanner className={bannerClassName}>
                    {banner}
                  </HeaderBanner>
                ) : null}
                {showCloseIcon && (
                  <IconButton
                    data-qa="close-premium-modal"
                    tabIndex="1"
                    icon={CloseModal}
                    onClick={onClose}
                    disabled={disableCloseIcon}
                    className={cx(styles.close, closeIconClassName)}
                  />
                )}
                {children}
              </Card>
            </OutsideClickHandler>
          </FocusOn>
        </ModalContextProvider>
      </ModalPlug>
    );
  },
);

Modal.displayName = 'Modal';

Modal.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  mobileFullScreen: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  overlayColor: PropTypes.string,
  disableOutsideClick: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  style: PropTypes.object,
  id: PropTypes.string,
  closeIconClassName: PropTypes.string,
  fullScreen: PropTypes.bool,
  banner: PropTypes.node,
  bannerClassName: PropTypes.string,
  size: PropTypes.oneOf([
    'extra-small',
    'small',
    'medium',
    'large',
    'extra-large',
    'maximum',
    'full-screen',
  ]),
  autoFocus: PropTypes.bool,
  disableCloseIcon: PropTypes.bool,
};

Modal.defaultProps = {
  onClose: () => ({}),
  overlayColor: 'rgba(4, 34, 56, 0.6)',
  mobileFullScreen: false,
  disableOutsideClick: false,
  showCloseIcon: false,
  id: 'modal',
};

Modal.Header = Header;
Modal.HeaderTitle = Title;
Modal.Footer = Footer;
Modal.Content = Content;

export default Modal;
