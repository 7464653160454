import React from 'react';
import useWindowSize from '@rooks/use-window-size';
import cx from 'classnames';
import { FORM_ERROR } from 'final-form';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import SidebarMarketing from '../../../components/SidebarMarketing';
import SignupForm from '../../../components/SignupForm';
import SocialSignup from '../../../components/SocialSignup';
import { smallScreenWidth } from '../../../constants/media-breakpoints';
import authPage from '../../../hoc/auth-page';
import MarketingLayout from '../../../layout/MarketingLayout';
import { segmentTracking } from '../../../services/utilities';
import academyLogo from '../login/assets/academy-logo.svg';
import academyBuildingImg from '../login/assets/building-illustration.svg';

import useSignupMutation from './useSignupMutation';

import styles from './AcademySignupPage.module.scss';

const AcademySignupPage = ({ location, history }) => {
  const signup = useSignupMutation(location);

  const MOBILE_BREAKPOINT = smallScreenWidth;
  const { innerWidth } = useWindowSize();
  const isMobile = innerWidth <= MOBILE_BREAKPOINT;

  const onSubmit = async (data) => {
    try {
      await signup({
        ...data,
        academy: true,
      });
      history.push('/onboarding/process' + history.location.search);
    } catch (error) {
      const parsedError = get(error, `graphQLErrors[0]`) || {};
      if (parsedError && parsedError.code === 471) {
        // return email error
        return {
          email: parsedError.message,
        };
      }
      if (parsedError && parsedError.code === 500) {
        return {
          [FORM_ERROR]: 'Something went wrong, please try again',
        };
      }
      const message = parsedError.message || 'Something went wrong';
      return {
        [FORM_ERROR]: message,
      };
    }
  };
  return (
    <MarketingLayout
      headerClassName={styles.header}
      LogoToReplace={academyLogo}
      onLogoClick={() =>
        segmentTracking('tt_logo clicked', { location: 'Landlord Signup' })
      }
      action={
        <Button
          style={{ width: isMobile ? 136 : 176 }}
          to={`/auth/academy/login`}
          small={true}
          secondary={true}
          //   onClick={() =>
          //     segmentTracking('landlord_login clicked', {
          //       location: 'Landlord Signup',
          //     })
          //   }
        >
          Academy Login
        </Button>
      }
      side={
        <SidebarMarketing
          className={styles.sidebar}
          title="UP YOUR LANDLORD GAME"
          subtitle={
            <>
              <span className={styles.sidebarTitleSpan}>Join the 504,000+</span>{' '}
              landlords that use TurboTenant to level up their property
              management business
            </>
          }
          graphic={academyBuildingImg}
        />
      }
    >
      <div className={cx(styles.container)}>
        <div className={cx(styles.content)}>
          <h2 className={cx(styles.title)}>
            Sign Up for <span>The Academy</span>
          </h2>
          <SocialSignup className={styles.social} academy={true} />
          <SignupForm onSubmit={onSubmit} mainFields={true} />
        </div>
      </div>
    </MarketingLayout>
  );
};

AcademySignupPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default authPage(AcademySignupPage);
