import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Exclamation = ({ color, ...rest }) => (
  <svg viewBox="0 0 25 24" fill={color || 'none'} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 2C18.0227 2 22.5 6.47726 22.5 12C22.5 17.5227 18.0227 22 12.5 22C6.97726 22 2.5 17.5227 2.5 12C2.5 6.47726 6.97726 2 12.5 2ZM12.5 16C11.81 16 11.25 16.56 11.25 17.25C11.25 17.94 11.81 18.5 12.5 18.5C13.19 18.5 13.75 17.94 13.75 17.25C13.75 16.56 13.19 16 12.5 16ZM12.5 5.5C11.9477 5.5 11.5 5.80659 11.5 6.18478V13.8152L11.5091 13.9081C11.5753 14.2424 11.9937 14.5 12.5 14.5C13.0523 14.5 13.5 14.1934 13.5 13.8152V6.18478L13.4909 6.09186C13.4247 5.75762 13.0063 5.5 12.5 5.5Z"
    />
  </svg>
);

Exclamation.propTypes = {
  color: PropTypes.string,
};

export default memo(Exclamation);
