import React from 'react';
import cx from 'classnames';

import Line from '../common/Line';

import styles from './Zebra.module.scss';

const generateItems = (numberOfItems) => {
  const items = [];
  for (let i = 0; i < numberOfItems; i++) {
    items.push(
      <div key={i} className={styles.skeletonZebraItem}>
        <span className={styles.optionIcon} />
        <Line size={'zebra'} />
      </div>,
    );
  }
  return items;
};
const Zebra = ({ className, numberOfItems }) => (
  <div className={cx(className, styles.skeletonZebra)}>
    {generateItems(numberOfItems)}
  </div>
);

export default Zebra;
