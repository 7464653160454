import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import CloseIcon from '../../icons/Close';
import MaintenanceAltColoredIcon from '../../icons/MaintenanceAltColored';
import Button from '../Button';
import Card from '../Card';

import styles from './LulaWaitlistBanner.module.scss';

const LulaWaitlistBanner = ({ className, onBannerDismiss, onLearnMore }) => {
  return (
    <Card className={cx(styles.container, className)}>
      <CloseIcon
        className={styles.close}
        width={14}
        height={14}
        onClick={onBannerDismiss}
      />
      <MaintenanceAltColoredIcon className={styles.maintenanceIcon} />
      <div className={styles.content}>
        <h3 className={styles.title}>
          A Maintenance process run for you, not by you.
        </h3>
        <p className={styles.description}>
          We provide troubleshooting, scheduling and vetted contractors while
          keeping you informed with real-time job status updates.
        </p>
      </div>
      <Button className={styles.btn} secondary onClick={onLearnMore}>
        Learn More
      </Button>
    </Card>
  );
};

LulaWaitlistBanner.propTypes = {
  className: PropTypes.string,
  onBannerDismiss: PropTypes.func,
  onLearnMore: PropTypes.func,
};

export default LulaWaitlistBanner;
