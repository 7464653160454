import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const LeaseNav = (props) => (
  <svg {...props} viewBox="0 0 18 22" fill={props.color}>
    <path d="m15.868 12.189 1.944 1.976a.66.66 0 0 1 0 .922l-5.834 5.931a.636.636 0 0 1-.276.166l-2.722.79c-.482.14-.927-.314-.79-.805l.778-2.767a.654.654 0 0 1 .163-.282l5.835-5.931a.63.63 0 0 1 .902 0Zm-5.873 7.286-.26.926.889-.259-.629-.667ZM11.112 0c.174 0 .34.073.46.2l3.386 3.546a.658.658 0 0 1 .177.45l.001 6.88c0 .359-.286.65-.639.65a.645.645 0 0 1-.638-.65V5.202a.627.627 0 0 1-.22.04h-3.176a.645.645 0 0 1-.639-.651v-3.29H1.278v17.533h5.985c.353 0 .64.292.64.651 0 .36-.287.651-.64.651H.64a.645.645 0 0 1-.639-.65V.65C0 .291.286 0 .639 0h10.473Zm2.943 14.953-3.346 3.401 1.018 1.079 3.367-3.423-1.039-1.057Zm-7.243.673c.353 0 .639.292.639.651 0 .36-.286.651-.64.651H3.285a.645.645 0 0 1-.638-.65c0-.36.286-.652.638-.652h3.528Zm8.605-2.057-.458.465 1.04 1.057.458-.465-1.04-1.057Zm-8.605-.547c.353 0 .639.291.639.65 0 .36-.286.652-.64.652H3.285a.645.645 0 0 1-.638-.651c0-.36.286-.651.638-.651h3.528Zm3.75-3.907c.352 0 .638.292.638.651 0 .36-.286.651-.638.651H3.284a.645.645 0 0 1-.638-.65c0-.36.286-.652.638-.652h7.278Zm0-2.604c.352 0 .638.291.638.651 0 .36-.286.651-.638.651H3.284a.645.645 0 0 1-.638-.651c0-.36.286-.651.638-.651h7.278ZM6.729 3.907c.352 0 .638.291.638.65 0 .36-.286.652-.638.652H3.284a.645.645 0 0 1-.638-.651c0-.36.286-.651.638-.651H6.73Zm6.633.033-2.261-2.363v2.364l2.261-.001Z" />
  </svg>
);

LeaseNav.defaultProps = {
  width: 18,
  height: 22,
  color: colors.greyLight,
};

LeaseNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(LeaseNav);
