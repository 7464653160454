import React from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Button from '../../../../components/Button';
import IconLabel from '../../../../components/IconLabel';
import colors from '../../../../constants/colors';
import UsersAccountFind from '../../../../icons/AccountFind';
import BusinessBank from '../../../../icons/BusinessBank';
import PiggyBank from '../../../../icons/PiggyBank';
import RealEstateHouseLock from '../../../../icons/RealEstateHouseLock';
import VerifiedBadgeIcon from '../../../../icons/VerifiedBadge';
import { useConfig } from '../../../../providers/ConfigProvider';

import ScreeningScoreBar from './ScreeningScoreBar';

import styles from './MainSection.module.scss';

const MainSection = ({
  report,
  screening_report_type,
  history,
  location,
  applicantId,
  shortVersion,
  firstName,
}) => {
  const { PRIVATE_BASE_PATH } = useConfig();

  const getResultDescriptionAndColor = (result) => {
    if (result === 'accept') {
      return {
        color: colors.green,
        description: (
          <span>
            An analysis of the credit history for this applicant indicates they
            meet the credit requirements. No additional conditions should be
            necessary other than your standard deposit. Criminal history, if
            any, is not factored into this recommendation - you should review
            the applicant’s criminal history to complete the applicant screening
            process. You can then inform the applicant of your final decision on
            leasing the property.
          </span>
        ),
      };
    } else if (result === 'lowaccept') {
      return {
        color: colors.lightGreen,
        description: (
          <span>
            The credit history for this applicant contains some events that
            might indicate a small risk in the applicant’s ability to meet the
            lease terms. The risk is still acceptable, but it’s recommended that
            you collect a deposit of ½ month’s rent in addition to your standard
            deposit. Criminal history, if any, is not factored into this
            recommendation - you should review the applicant’s criminal history
            to complete the applicant screening process. You can then inform the
            applicant of your final decision on leasing the property.
          </span>
        ),
      };
    } else if (result === 'conditionalaccept' || result === 'conditional') {
      return {
        color: colors.pink,
        description: (
          <span>
            The credit history for this applicant contains events that indicate
            a reasonable risk in the applicant’s ability to meet the lease
            terms. The risk is acceptable but it’s highly recommended that you
            collect a deposit of 1 month’s rent in addition to your standard
            deposit. Criminal history, if any, is not factored into this
            recommendation - you should review the applicant’s criminal history
            to complete the applicant screening process. You can then inform the
            applicant of your final decision on leasing the property.
          </span>
        ),
      };
    } else if (result === 'decline') {
      return {
        color: colors.errorText,
        description: (
          <span>
            The credit history for this applicant contains adverse events that
            indicate a significant risk in the applicant’s ability to meet the
            lease terms. The risk is significant enough that you should not
            lease to this applicant without additional financial security (e.g.
            a cosigner/guarantor). At this point no further steps are necessary
            other than to communicate your decision to the applicant.
          </span>
        ),
      };
    } else if (
      result === 'refer' ||
      result === 'notavailable' ||
      result === 'not available'
    ) {
      return {
        color: colors.grey1,
        description: (
          <span>
            This applicant does not possess enough historical data to make a
            recommendation on their risk level. This is not necessarily a
            negative, but the applicant does represent a moderate risk. We
            recommend you confirm their employment history and collect ½ month’s
            rent in addition to your standard deposit.{' '}
            <b>
              Criminal history, if any, is not factored into this recommendation
            </b>
            .
          </span>
        ),
      };
    } else if (result === 'frozen') {
      return {
        color: colors.info,
        description: (
          <span>
            The credit report could not be retrieved as it has been frozen by
            the applicant. A frozen report indicates that the applicant has
            disallowed the release of their report from any credit reporting
            agency. The applicant must contact TransUnion at 866-775-0961 to
            un-freeze their report.
          </span>
        ),
      };
    }

    return {
      color: colors.black,
      description: '',
    };
  };

  const getFontColor = (type, value) => {
    switch (type) {
      case 'score': {
        if (value >= 750) {
          // 790 .. 850 'Excellent', 750 .. 789 'Very Good'
          return colors.green;
        }
        if (value >= 660) {
          return '#CCCC00'; // 'Good'
        }
        if (value >= 550) {
          return '#FF9933'; // 'Fair'
        }
        return colors.errorText; // 'Poor'
      }
      case 'employers': {
        if (value === 0) {
          return colors.errorText;
        }
        return colors.green;
      }
      default: {
        if (value === 0) {
          return colors.green;
        }
        if (value === 'N/A') {
          return colors.grey1;
        }
        return colors.errorText;
      }
    }
  };

  const MyIcon = ({ subtitle, color, value, footer, icon }) => {
    return (
      <IconLabel
        label={
          <div className={styles.iconLabelContainer}>
            <span className={styles.subTitle}>{subtitle}</span>
            <span
              className={styles.iconNumber}
              style={{
                color: getFontColor(color, value),
              }}
            >
              {value}
            </span>
            <span className={styles.iconFooter}>{footer}</span>
          </div>
        }
        iconProps={{
          width: 56,
          height: 56,
        }}
        icon={icon}
        className={styles.iconContainer}
      />
    );
  };

  const renderIcons = () => {
    const shouldRenderScore = /^\d+$/g.test(report.score) && +report.score > 0;

    const alignLeft =
      (report.recommendation.title &&
        report.recommendation.title.toLowerCase() === 'refer') ||
      !shouldRenderScore;
    const icons = [
      {
        subtitle: 'Criminal History',
        color: 'criminal',
        value: report.number_of_criminal_records,
        footer: 'RECORDS FOUND',
        icon: UsersAccountFind,
      },
      {
        subtitle: 'Evictions',
        color: 'evictions',
        value:
          screening_report_type === 'PackageCorePlusEviction'
            ? report.number_of_evictions
            : 'N/A',
        footer: 'RECORDS FOUND',
        icon: RealEstateHouseLock,
      },
      shouldRenderScore && {
        subtitle: 'Collections',
        color: 'collections',
        value: report.collections.length,
        footer: 'ACCOUNTS',
        icon: PiggyBank,
      },
      {
        subtitle: 'Public Records',
        color: 'public_records',
        value: report.public_records.length,
        footer: 'RECORDS FOUND',
        icon: BusinessBank,
      },
    ];
    return (
      <div
        className={cx(styles.iconsContainer, {
          [styles.onLeft]: alignLeft,
        })}
      >
        {icons.map(
          (element, ix) => element && <MyIcon {...element} key={ix} />,
        )}
      </div>
    );
  };

  const handleonTabChange = () => {
    let searchParams = queryString.parse(location.search);
    searchParams = {
      ...searchParams,
      scrollToTop: true,
    };
    return history.push(
      `${PRIVATE_BASE_PATH}applicant-profile/${applicantId}/tab_screening_report?${queryString.stringify(
        searchParams,
      )}`,
    );
  };

  const renderSubtitle = () => {
    if (!report.recommendation.title) return null; // TODO TU won't be sending recommendation anymore

    const { color, description } = getResultDescriptionAndColor(
      report.recommendation.title.toLowerCase(),
    );
    let recommendation = report.recommendation.title.match(/[A-Z][a-z]*/g);
    recommendation = recommendation
      ? recommendation.join(' ')
      : report.recommendation.title;
    const isRefer = report.recommendation.title.toLowerCase() === 'refer';

    return (
      <div style={{ marginTop: 8 }}>
        {isRefer ? (
          <span className={styles.title}>Insufficient credit history.</span>
        ) : (
          <span className={styles.subTitle}>
            TransUnion Credit Bureau Recommendation:{' '}
            <span style={{ color: color }}>{recommendation}</span>
          </span>
        )}
        <div className={styles.descriptionContainer}>
          <span style={{ flexGrow: 1, marginRight: 16, textAlign: 'justify' }}>
            {description}
          </span>
          {shortVersion && (
            <Button
              variant="secondary"
              label="VIEW FULL REPORT"
              labelStyle={{ fontSize: 12 }}
              onClick={handleonTabChange}
              id="view_full_report"
              className={styles.fullReportButton}
            />
          )}
        </div>
      </div>
    );
  };

  const shouldRenderScore = /^\d+$/g.test(report.score) && +report.score > 0;
  const shouldRenderScoreFactors =
    report.score_factors.length > 0 &&
    report.recommendation.title.toLowerCase() !== 'refer';
  if (!shouldRenderScore) {
    return (
      <div>
        <p className={styles.blueSubtitle}>Insufficient Credit History</p>
        <div>
          <span style={{ flexGrow: 1, marginRight: 16, textAlign: 'justify' }}>
            No credit score is available since this applicant has not
            established a credit file with the credit bureau. When making a
            decision, we recommend reviewing their criminal and eviction
            history, if they have any. Also, consider a co-signer or higher
            security deposit if your state and local laws allow it.
          </span>
          {shortVersion && (
            <Button
              variant="secondary"
              label="VIEW FULL REPORT"
              labelStyle={{ fontSize: 12 }}
              onClick={handleonTabChange}
              id="view_full_report"
              className={styles.fullReportButton}
            />
          )}
        </div>
        {renderIcons()}
        <div
          className={(styles.verifiedWrapper, styles.extendedVerifiedWrapper)}
        >
          <div className={styles.verifiedBadge}>
            <VerifiedBadgeIcon className={styles.verifiedBadgeIcon} />
            IDENTITY VERIFIED
          </div>
          {firstName && (
            <span className={styles.verifiedText}>
              TransUnion verified {firstName}'s identity.
            </span>
          )}
        </div>
      </div>
    );
  }
  return (
    <div>
      {renderSubtitle()}
      <div className={styles.contentContainer}>
        {shouldRenderScoreFactors && (
          <div>
            <ScreeningScoreBar score={report.score} />
            {shouldRenderScoreFactors && (
              <div className={styles.columnScoreFactor}>
                <span className={styles.subTitle} style={{ marginBottom: 12 }}>
                  Score Factors
                </span>
                {report.score_factors
                  .filter((s) => !!s)
                  .map((element, idx) => (
                    <div key={idx} className={styles.listItem}>
                      <span className={styles.listItemBefore} />
                      {element}
                    </div>
                  ))}
              </div>
            )}
            <div>
              <div>
                <VerifiedBadgeIcon className={styles.verifiedWrapper} />
                IDENTITY VERIFIED
              </div>
              {firstName && (
                <span className={styles.verifiedText}>
                  TransUnion verified {firstName}'s identity.
                </span>
              )}
            </div>
          </div>
        )}
        {renderIcons()}
      </div>
    </div>
  );
};

MainSection.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  report: PropTypes.object,
  screening_report_type: PropTypes.string,
  shortVersion: PropTypes.bool,
  firstName: PropTypes.string,
  styles: PropTypes.object,
};

export default withRouter(MainSection);
