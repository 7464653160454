import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import ToolboxNav from '../../../../../../../icons/ToolboxNav';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './PendingSuits.module.scss';

const PendingSuitsOR = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle
        title="Pending Suits"
        icon={ToolboxNav}
        className={styles.sectionTitle}
      />
      <label className={styles.label}>
        Do you own four or fewer rental units that have any pending suits
        against the property?
      </label>
      <div className={styles.questionContext}>
        Oregon law requires that you disclose this.
      </div>
      <Field
        component={RadioGroup}
        id="custom_state_data.has_pending_suits"
        name="custom_state_data.has_pending_suits"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'has_pending_suits_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'has_pending_suits_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.has_pending_suits" is={true}>
        <>
          <p className={styles.text}>
            You will need to disclose the following information: all pending
            proceedings to foreclose a tax lien or foreclose a mortgage, deed of
            trust of vendor’s lien under a contract of sale, any outstanding
            notice of default under a trust deed, mortgage or notice of Public
            Trustee’s sale, and/or any pending declaration of forfeiture or suit
            for specific performance of a contract of sale:
          </p>
          <Field
            as="textarea"
            component={Input}
            id="custom_state_data.pending_suits_explanation"
            name="custom_state_data.pending_suits_explanation"
            className={styles.termsContainer}
            inputProps={{
              rows: '5',
              className: styles.termsField,
            }}
            maxLength={2000}
            validate={fieldRequired}
            help={(val = '') => `${val.length} / 2000 characters used`}
          />
        </>
      </Condition>
    </div>
  );
};

PendingSuitsOR.propTypes = {
  className: PropTypes.string,
};

export default PendingSuitsOR;
