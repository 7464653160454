import React from 'react';
import { bool, func } from 'prop-types';

import IntercomSetUp from '../setup-components/IntercomSetUp';
import SegmentSetup from '../setup-components/SegmentSetup';
import SiftScienceSetup from '../setup-components/SiftScienceSetup';

const GlobalComponents = () => {
  return (
    <>
      <SiftScienceSetup />
      <IntercomSetUp />
      <SegmentSetup />
    </>
  );
};

GlobalComponents.propTypes = {
  closeModal: func,
  errorModalOpened: bool,
};

export default GlobalComponents;
