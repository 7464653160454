import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import WarningIcon from '../../icons/Warning';
import Button from '../Button';
import Modal from '../Modal';

import styles from './AccountBlocklistedModal.module.scss';

const AccountBlocklistedModal = ({
  className,
  onDone,
  userType,
  accountClosed,
}) => (
  <Modal mobileFullScreen open className={cx(styles.container, className)}>
    <WarningIcon className={styles.warning} />
    <h2 className={styles.title}>
      {accountClosed
        ? 'Your account has been closed.'
        : userType !== 'owner'
        ? 'Your account has been flagged for suspicious activity.'
        : 'Your account has been suspended'}
    </h2>
    <p className={styles.description}>
      Please contact{' '}
      <a href="mailto:support@turbotenant.com">support@turbotenant.com</a>{' '}
      {accountClosed
        ? 'if you need your account reactivated.'
        : 'to get help resolving this issue.'}
      {!accountClosed && userType === 'owner'
        ? ' You will now be locked out of your account.'
        : ''}
    </p>
    <Button className={styles.btn} onClick={onDone}>
      DONE
    </Button>
  </Modal>
);

AccountBlocklistedModal.propTypes = {
  className: PropTypes.string,
  onDone: PropTypes.func,
  userType: PropTypes.oneOf(['owner', 'renter']),
  accountClosed: PropTypes.bool,
};

AccountBlocklistedModal.defaultProps = {
  userType: 'owner',
};

export default AccountBlocklistedModal;
