const getReiHubPrice = (
  pricings = [],
  unitCount,
  defaultQuarterlyCountForPrice = false,
) => {
  // 14 is default for quarterly and 21 is default for yearly
  const defaultCountForPrice = defaultQuarterlyCountForPrice ? 14 : 21;

  if (unitCount > defaultCountForPrice) return pricings['default'];

  return pricings[unitCount] || pricings[1];
};

export default getReiHubPrice;
