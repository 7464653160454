import { useConfig } from '../../providers/ConfigProvider';

const usePricesFromShortCodes = (short_code) => {
  const {
    GBB_FORMS_PACK_YEARLY_199_SHORT_CODE,
    GBB_SINGLE_LEASE_YEARLY_59_SHORT_CODE,
    GBB_PRO_YEARLY_119_SHORT_CODE,
    GBB_PREMIUM_YEARLY_149_SHORT_CODE,
    GBB_PREMIUM_YEARLY_179_SHORT_CODE,
    GBB_PREMIUM_YEARLY_199_SHORT_CODE,
  } = useConfig();

  const shortCodesWithPrices = {
    [GBB_PRO_YEARLY_119_SHORT_CODE]: 119,
    [GBB_PREMIUM_YEARLY_149_SHORT_CODE]: 149,
    [GBB_PREMIUM_YEARLY_179_SHORT_CODE]: 179,
    [GBB_PREMIUM_YEARLY_199_SHORT_CODE]: 199,
    [GBB_SINGLE_LEASE_YEARLY_59_SHORT_CODE]: 59,
    [GBB_FORMS_PACK_YEARLY_199_SHORT_CODE]: 199,
  };

  return shortCodesWithPrices?.[short_code];
};

export default usePricesFromShortCodes;
