import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const DashboardNav = (props) => (
  <svg {...props} viewBox="0 0 22 16" fill={props.color}>
    <path d="M11 0c6.082 0 11 5.034 11 11.23v4.155c0 .34-.281.615-.629.615H.63A.622.622 0 0 1 0 15.385V11.23C0 5.034 4.918 0 11 0Zm0 1.23c-5.375 0-9.743 4.472-9.743 10v3.54h19.486v-3.54c0-5.528-4.368-10-9.743-10Zm5.797 4.155c.246.24.245.63-.001.87l-4.035 3.936c.13.256.203.544.203.85 0 1.06-.88 1.918-1.964 1.918s-1.964-.859-1.964-1.918c0-1.06.88-1.919 1.964-1.919.313 0 .609.072.872.199l4.037-3.938a.638.638 0 0 1 .888.002ZM4.321 10.86c.348 0 .629.275.629.615 0 .34-.281.615-.629.615h-1.78a.622.622 0 0 1-.63-.615c0-.34.282-.615.63-.615h1.78Zm15.139 0c.347 0 .628.275.628.615 0 .34-.281.615-.628.615h-1.781a.622.622 0 0 1-.629-.615c0-.34.281-.615.629-.615h1.78ZM11 10.353a.698.698 0 0 0-.707.688c0 .379.316.688.707.688.39 0 .707-.31.707-.688a.698.698 0 0 0-.707-.688Zm8.396-2.705a.61.61 0 0 1-.341.803l-1.646.665a.633.633 0 0 1-.82-.334.61.61 0 0 1 .34-.804l1.646-.664c.321-.13.689.02.821.334ZM3.425 7.314l1.644.664c.321.13.474.49.342.804a.633.633 0 0 1-.821.334L2.945 8.45a.61.61 0 0 1-.341-.803.633.633 0 0 1 .82-.334Zm2.038-2.545L6.72 5.998c.246.24.246.63.001.87a.638.638 0 0 1-.889 0L4.575 5.64a.606.606 0 0 1 0-.87.638.638 0 0 1 .888 0Zm9.015-1.922c.32.13.472.49.34.805l-.682 1.605a.633.633 0 0 1-.822.332.61.61 0 0 1-.339-.804l.681-1.606a.633.633 0 0 1 .822-.332Zm-6.134.332.681 1.606a.61.61 0 0 1-.34.804.633.633 0 0 1-.821-.332l-.681-1.605a.61.61 0 0 1 .34-.805c.32-.13.688.019.82.332ZM11 2.172c.347 0 .629.275.629.615v1.738c0 .34-.282.615-.629.615a.622.622 0 0 1-.629-.615V2.787c0-.34.282-.615.629-.615Z" />
  </svg>
);

DashboardNav.defaultProps = {
  width: 22,
  height: 16,
  color: colors.greyLight,
};

DashboardNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(DashboardNav);
