import React from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import LoadingScreen from '../../../../../components/LoadingScreen';
import { useErrorToast } from '../../../../../components/Toast';
import { useLeases, useUserProfile } from '../../../../../core/TTgraphql';
import basicUserProfile from '../../../../../graphql/basicUserProfile.graphql';
import completeSetupGQL from '../../../../../graphql/mutations/payments/completeRentPaymentsSetup.graphql';
import rentPaymentsQuery from '../../../../../graphql/queries/rentPaymentsQuery.graphql';
import { getUserBankAccounts } from '../../../../../helpers/getUserBankAccounts';
import { usePaymentRequestRules } from '../../../usePayments';
import ConfirmRentPaymentsSetup from '../ConfirmRentPaymentsSetup';

import styles from './SetupConfirmationWrapper.module.scss';

const SetupConfirmationWrapper = ({ onNext, onEdit, ...rest }) => {
  const { leaseId } = queryString.parse(location.search, {
    parseBooleans: true,
  });

  const showErrorMessage = useErrorToast();
  const { user, loading: loadingUser } = useUserProfile();
  const { leases = [], loading: loadingLeases } = useLeases();

  const lease = leases.find((l) => l.id === leaseId) || leases[0];

  const { rules, loading: chargesListLoading } = usePaymentRequestRules({
    variables: { lease_id: lease?.id },
    skip: !lease?.id,
  });

  const [completeSetup, { loading }] = useMutation(completeSetupGQL, {
    refetchQueries: [{ query: basicUserProfile }, { query: rentPaymentsQuery }],
    awaitRefetchQueries: true,
  });

  const onSubmit = async () => {
    try {
      const bankAccounts = getUserBankAccounts(user);

      const checkNoBankSelected =
        user.premium_subscription_subscribed && bankAccounts.length > 1;

      if (checkNoBankSelected) {
        const thereIsChargeWithoutBank = rules.some((r) => !r.destination_id);
        if (thereIsChargeWithoutBank)
          return showErrorMessage(
            'A charge is missing a bank account assignment',
          );
      }

      const { data } = await completeSetup({
        variables: { leaseId: lease.id },
      });
      if (!data.completeRentPaymentsSetup?.ok) {
        showErrorMessage('Something went wrong');
      }

      onNext(lease.id);
    } catch (error) {
      console.log(error);
      showErrorMessage('Something went wrong');
    }
  };

  return (
    <LoadingScreen
      loading={loadingLeases || chargesListLoading || loadingUser}
      className={styles.loadingContainer}
      id="setup-confirmation-wrapper-loading-container"
    >
      <ConfirmRentPaymentsSetup
        onSubmit={onSubmit}
        {...rest}
        lease={lease}
        rules={rules}
        verification_documents={user ? user.verification_documents : []}
        user={user}
        onEdit={onEdit}
        loading={loading}
      />
    </LoadingScreen>
  );
};

SetupConfirmationWrapper.propTypes = {
  onNext: PropTypes.func,
};

export default SetupConfirmationWrapper;
