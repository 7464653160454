import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const MessagesNav = (props) => (
  <svg {...props} viewBox="0 0 20 18" fill={props.color}>
    <path d="M19.35 7.304a.65.65 0 0 1 .65.652v6.26a.65.65 0 0 1-.65.653h-.908v2.478a.65.65 0 0 1-1.081.487l-3.322-2.965H10a.65.65 0 0 1-.65-.653v-6.26c0-.36.291-.652.65-.652Zm-.649 1.304H10.65v4.956h3.637c.159 0 .312.059.431.165l2.426 2.165v-1.678c0-.36.29-.652.65-.652h.908V8.608ZM16.234 0a.65.65 0 0 1 .65.652v5.51a.65.65 0 0 1-.65.653.65.65 0 0 1-.65-.652V1.304H1.3v8.87H3.11a.65.65 0 0 1 .65.651v1.784l2.165-2.238a.648.648 0 0 1 .466-.198h.82a.65.65 0 0 1 .65.652.65.65 0 0 1-.65.652h-.546l-3.09 3.194a.649.649 0 0 1-1.114-.455v-2.739H.649A.65.65 0 0 1 0 10.825V.652C0 .292.29 0 .65 0Z" />
  </svg>
);

MessagesNav.defaultProps = {
  width: 20,
  height: 18,
  color: colors.greyLight,
};

MessagesNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(MessagesNav);
