import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const HelpNav = (props) => (
  <svg {...props} viewBox="0 0 20 20" fill={props.color}>
    <path d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0Zm0 1.277a8.724 8.724 0 1 0 0 17.447 8.724 8.724 0 0 0 0-17.447Zm0 12.766a1.064 1.064 0 1 1 0 2.128 1.064 1.064 0 0 1 0-2.128Zm0-9.362A3.192 3.192 0 0 1 10.638 11v1.979a.638.638 0 1 1-1.276 0v-2.553c0-.353.285-.639.638-.639a1.915 1.915 0 1 0-1.915-1.915.638.638 0 1 1-1.276 0A3.192 3.192 0 0 1 10 4.681Z" />
  </svg>
);

HelpNav.defaultProps = {
  width: 20,
  height: 20,
  color: colors.greyLight,
};

HelpNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(HelpNav);
