import React, { useEffect, useRef, useState } from 'react';
import { Field, FormSpy, useField } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import cx from 'classnames';
import PropTypes from 'prop-types';

import FileUploadPreview from '../../../../../components/FileUploadPreview';
import Input from '../../../../../components/Input';
import RadioGroup from '../../../../../components/RadioGroup';
import UploadDropZone from '../../../../../components/UploadDropZone';
import { SUB_DIVISION_NAMES_KEYS } from '../../../../../constants/lease_agreement/sub-division-names';
import PhotosIcon from '../../../../../icons/Photos';
import { useConfig } from '../../../../../providers/ConfigProvider';
import fieldRequiredStandard from '../../../../../validators/fieldRequired';
import validateForHelloSign from '../../../../../validators/validateForHelloSign';
import SectionInfoBlock from '../../../common/SectionInfoBlock';
import { SectionAdditionalProvisionsAttachmentsSection } from '../SectionAdditionalProvisionsAttachmentsSection';
import { SectionAdditionalProvisionsBillOfRightsSection } from '../SectionAdditionalProvisionsBillOfRightsSection';
import { SectionAdditionalProvisionsLeadSection } from '../SectionAdditionalProvisionsLeadSection';
import { SectionAdditionalProvisionsTermsSection } from '../SectionAdditionalProvisionsTermsSection';
import BedbugReportingDisclosureAZ from '../state_specific/AZ/BedbugReportingDisclosureAZ';
import PoolDisclosureAZ from '../state_specific/AZ/PoolDisclosureAZ';
import AsbestosNoticeCA from '../state_specific/CA/AsbestosNoticeCA';
import BedbugReportingDisclosureCA from '../state_specific/CA/BedbugReportingDisclosureCA';
import DeathDisclosureCA from '../state_specific/CA/DeathDisclosureCA';
import DrugDisclosureCA from '../state_specific/CA/DrugDisclosureCA';
import FloodDisclosureCA from '../state_specific/CA/FloodDisclosureCA';
import MilitaryTestingDisclosureCA from '../state_specific/CA/MilitaryTestingDisclosureCA';
import MoldDisclosureCA from '../state_specific/CA/MoldDisclosureCA';
import RentControlDisclosureCA from '../state_specific/CA/RentControlDisclosureCA';
import RadonDisclosureCO from '../state_specific/CO/RadonDisclosureCO';
import BedbugReportingDisclosureCT from '../state_specific/CT/BedbugReportingDisclosure';
import CommonInterestCommunityDisclosureCT from '../state_specific/CT/CommonInterestCommunityDisclosure';
import CTSprinklerDisclosure from '../state_specific/CT/SprinklerDisclosure';
import BuildingConditionDisclosureDC from '../state_specific/DC/BuildingConditionDisclosureDC';
import CodeViolationsDC from '../state_specific/DC/CodeViolationsDC';
import MoldDisclosureDC from '../state_specific/DC/MoldDisclosureDC';
import OwnershipAndLicensingDisclosureDC from '../state_specific/DC/OwnershipAndLicensingDisclosureDC';
import RentalSurchargesDC from '../state_specific/DC/RentalSurchargesDC';
import RentControlDisclosure from '../state_specific/DC/RentControlDisclosure';
import FloodDisclosure from '../state_specific/GA/FloodDisclosure';
import SuperfundDisclosureIA from '../state_specific/IA/SuperfundDisclosureIA';
import RadeonDisclosure from '../state_specific/IL/RadeonDisclosure';
import RentConcessions from '../state_specific/IL/RentConcessions';
import FloodDisclosureIN from '../state_specific/IN/FloodDisclosureIN';
import FloodDisclosureMD from '../state_specific/MD/FloodDisclosureMD';
import HabitabilityMD from '../state_specific/MD/HabitabilityMD';
import BedbugReportingDisclosureME from '../state_specific/ME/BedbugReportingDisclosure';
import EnergyEfficiencyDisclosureME from '../state_specific/ME/EnergyEfficiencyDisclosure';
import RadonDisclosureME from '../state_specific/ME/RadonDisclosure';
import FinancialDistressDisclosureMN from '../state_specific/MN/FinancialDistressDisclosureMN';
import InspectionCondemnationMN from '../state_specific/MN/InspectionCondemnationMN';
import MethamphetamineManufacturingMT from '../state_specific/MT/MethamphetamineManufacturingMT';
import MoldDisclosureMT from '../state_specific/MT/MoldDisclosureMT';
import FloodZone from '../state_specific/NJ/FloodZone';
import TruthInRenting from '../state_specific/NJ/TruthInRenting/TruthInRenting';
import NewYorkAdditionalProvisions from '../state_specific/NY/AdditionalProvisions';
import OKFloodDisclosure from '../state_specific/OK/FloodDisclosure';
import ORFloodDisclosure from '../state_specific/OR/FloodDisclosure';
import PendingSuitsOR from '../state_specific/OR/PendiungSuits';
import DisclosureOfPriorManufacturingOfMeth from '../state_specific/SD/DisclosureOfPriorManufacturingOfMeth';
import TXFloodDisclosure from '../state_specific/TX/FloodDisclosure';
import MethamphetamineManufacturing from '../state_specific/UT/MethamphetamineManufacturing';
import DrywallDisclosureVA from '../state_specific/VA/DrywallDisclosureVA';
import MethamphetamineManufacturingVA from '../state_specific/VA/MethamphetamineManufacturingVA';
import MilitaryInstallationDisclosureVA from '../state_specific/VA/MilitaryInstallationDisclosureVA';
import MoldDisclosureVA from '../state_specific/VA/MoldDisclosureVA';
import FireSafetyDisclosureWA from '../state_specific/WA/FireSafetyDisclosureWA';
import MoldDisclosureWA from '../state_specific/WA/MoldDisclosureWA';
import OccupantDisclosureWA from '../state_specific/WA/OccupantDisclosureWA';
import UninhabitableSpaceDisclosureWA from '../state_specific/WA/UninhabitableSpaceDisclosureWA';
import CodeViolations from '../state_specific/WI/CodeViolations/CodeViolations';

import styles from './SectionAdditionalProvisionsFields.module.scss';

const noop = () => {};

const SectionAdditionalProvisionsFields = ({
  leaseId,
  setPristine,
  currentState,
  leaseAgreementSelection,
  propertyRole,
  leaseRiderUploadFiles,
  bedBugsUploadFiles,
  handleRemoveAttachment,
  attachmentTypes,
  attachmentErrors,
  uploadAttachments,
  uploadAttachmentFilter,
  leaseRiderFilesFilter,
  bedBugsFilesFilter,
  standAloneWizard,
  onCreateAccount,
}) => {
  const { ASSET_BUCKET_URL } = useConfig();
  // Focus on the associated textarea input when "Yes" is selected using the
  // radio buttons.
  const [fieldRefToFocus, setFieldRefToFocus] = useState(null);
  const leadPaintHazardsField = useRef(null);
  const leadPaintRecordsField = useRef(null);

  useEffect(() => {
    if (fieldRefToFocus?.current) {
      fieldRefToFocus.current.focus();
    }
  }, [fieldRefToFocus]);

  const {
    input: { value: billOfRights },
  } = useField('custom_state_data.bill_of_rights_required');

  const {
    input: { value: leadPaintDisclosure },
  } = useField('lead_paint_disclosure_required');

  const {
    input: { value: hasLeadBasedHazards },
  } = useField('has_lead_based_hazards');

  const {
    input: { value: hasLeadBasedRecords },
  } = useField('has_lead_based_records');

  const fieldRequired = standAloneWizard ? noop : fieldRequiredStandard;

  return (
    <>
      <FormSpy
        subscription={{ pristine: true, values: true, dirtyFields: true }}
        onChange={({ pristine, dirtyFields }) => {
          // NOTE (#UNAUTH-TODO / tech-debt): react-final-form does not perform a deep comparison of form state. Therefore, fields with
          // object type data value, like attachments ([]), will always be considered dirty. We might look into
          // using a custom form state comparison function in the future. For now, we will not consider dirty, scenarios
          // where the attachments are the only dirty field.
          const falseNegativePristine =
            Object.keys(dirtyFields).length === 1 && dirtyFields.attachments;
          if (!falseNegativePristine) {
            setPristine(pristine);
          }
        }}
      />
      <SectionAdditionalProvisionsTermsSection
        state={currentState}
        leaseAgreementSelection={leaseAgreementSelection}
        propertyRole={propertyRole}
      >
        <Field
          as="textarea"
          component={Input}
          id="additional_terms"
          name="additional_terms"
          label="ADDITIONAL TERMS"
          hint="(Optional)"
          className={styles.termsContainer}
          inputProps={{
            'data-qa': 'lease-agreements-additional-terms-input',
            'rows': '8',
            'className': styles.termsField,
          }}
          maxLength={10000}
          help={(val = '') => `${val.length} / 10000 characters used`}
          validate={validateForHelloSign}
        />
      </SectionAdditionalProvisionsTermsSection>

      {currentState === 'FL' && (
        <SectionAdditionalProvisionsBillOfRightsSection>
          <div className={styles.column}>
            <Field
              component={RadioGroup}
              id="custom_state_data.bill_of_rights_required"
              name="custom_state_data.bill_of_rights_required"
              className={styles.radioWrapper}
              radiosContainerClassName={cx(styles.radioButtonsContainer)}
              label="Is your premise located in Miami-Dade County?"
              labelProps={{
                className: styles.headerLabel,
              }}
              options={[
                {
                  'id': 'bill_of_rights_required_choice_yes',
                  'value': true,
                  'label': 'Yes',
                  'data-qa':
                    'is-located-dade-county-choice-yes-radio-radio-yes',
                },
                {
                  id: 'bill_of_rights_required_choice_no',
                  value: false,
                  label: 'No',
                },
              ]}
              validate={fieldRequired}
            />
            {billOfRights && (
              <SectionInfoBlock className={styles.infoBlock}>
                For premises located in Miami/Dade County, local law requires
                you attach the{' '}
                <a
                  href={`${ASSET_BUCKET_URL}uploads/assets/notice-of-tenants-rights.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Miami/Dade County Tenant Bill of Rights
                </a>{' '}
                with the lease and each party must sign it. We’ve already
                attached it for you.
              </SectionInfoBlock>
            )}
          </div>
        </SectionAdditionalProvisionsBillOfRightsSection>
      )}

      <SectionAdditionalProvisionsLeadSection>
        <div className={styles.column}>
          <Field
            component={RadioGroup}
            id="lead_paint_disclosure_required"
            name="lead_paint_disclosure_required"
            className={styles.radioWrapper}
            radiosContainerClassName={cx(styles.radioButtonsContainer)}
            label="Was this property built prior to January 1st, 1978?"
            labelProps={{
              className: styles.headerLabel,
            }}
            options={[
              {
                'id': 'lead_paint_disclosure_required_choice_yes',
                'value': true,
                'label': 'Yes',
                'data-qa':
                  'lease-agreements-lead-paint-disclosure-required-radio-yes',
              },
              {
                id: 'lead_paint_disclosure_required_choice_no',
                value: false,
                label: 'No',
              },
            ]}
            validate={fieldRequired}
          />

          {leadPaintDisclosure && (
            <div className={styles.column}>
              <SectionInfoBlock className={styles.infoBlock}>
                <strong>Federal Pamphlet:</strong> We will automatically attach
                the{' '}
                <a
                  href={`${ASSET_BUCKET_URL}uploads/assets/epa_lead_paint_pamphlet.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Protect Your Family From Lead In Your Home
                </a>{' '}
                pamphlet that the federal government requires you give to
                tenants when the home was built before 1978.
              </SectionInfoBlock>

              <OnChange name="has_lead_based_hazards">
                {(value) => {
                  setFieldRefToFocus(value ? leadPaintHazardsField : null);
                }}
              </OnChange>
              <Field
                component={RadioGroup}
                id="has_lead_based_hazards"
                name="has_lead_based_hazards"
                className={styles.radioWrapper}
                radiosContainerClassName={cx(styles.radioButtonsContainer)}
                label="Are you aware of lead-based paint and/or lead-based paint hazards present in the housing?"
                labelProps={{
                  className: styles.headerLabel,
                }}
                options={[
                  {
                    'id': 'has_lead_based_hazards_choice_yes',
                    'value': true,
                    'label': 'Yes',
                    'data-qa':
                      'lease-agreements-has-lead-based-hazards-radio-yes',
                  },
                  {
                    id: 'has_lead_based_hazards_choice_no',
                    value: false,
                    label: 'No',
                  },
                ]}
                validate={fieldRequired}
              />

              {hasLeadBasedHazards && (
                <Field
                  label="PLEASE EXPLAIN"
                  className={styles.termsContainer}
                  as="textarea"
                  component={Input}
                  id="lead_paint_hazards"
                  name="lead_paint_hazards"
                  inputProps={{
                    'data-qa': 'lease-agreements-lead-hazards-input',
                    'rows': 3,
                    'className': styles.termsField,
                    'ref': (ref) => (leadPaintHazardsField.current = ref),
                  }}
                  maxLength={1000}
                  help={(val = '') => `${val.length} / 1000 characters used`}
                  validate={fieldRequired}
                />
              )}

              <OnChange name="has_lead_based_records">
                {(value) => {
                  setFieldRefToFocus(value ? leadPaintRecordsField : null);
                }}
              </OnChange>
              <Field
                component={RadioGroup}
                id="has_lead_based_records"
                name="has_lead_based_records"
                className={styles.radioWrapper}
                radiosContainerClassName={cx(styles.radioButtonsContainer)}
                label="Do you have any records or reports regarding lead-based paint,
              pertaining to the housing?"
                labelProps={{
                  className: styles.headerLabel,
                }}
                options={[
                  {
                    'id': 'has_lead_based_records_choice_yes',
                    'value': true,
                    'label': 'Yes',
                    'data-qa':
                      'lease-agreements-lead-hazards-records-radio-yes',
                  },
                  {
                    id: 'has_lead_based_records_choice_no',
                    value: false,
                    label: 'No',
                  },
                ]}
                validate={fieldRequired}
              />

              {hasLeadBasedRecords && (
                <React.Fragment>
                  <p className={styles.description}>
                    Please list the records and/or reports below. For example:{' '}
                    <i>
                      On 05/23/1991 there was a report generated by the ABC
                      Company Ltd. that showed evidence of lead-based paint.
                    </i>{' '}
                    <strong>
                      Remember to share a copy of the records / reports with
                      your tenants.
                    </strong>
                  </p>

                  <Field
                    label="PLEASE EXPLAIN"
                    className={styles.termsContainer}
                    as="textarea"
                    component={Input}
                    id="lead_paint_records"
                    name="lead_paint_records"
                    inputProps={{
                      'data-qa': 'lease-agreements-lead-hazards-records-input',
                      'rows': 3,
                      'className': styles.termsField,
                      'ref': (ref) => (leadPaintRecordsField.current = ref),
                    }}
                    maxLength={1000}
                    help={(val = '') => `${val.length} / 1000 characters used`}
                    validate={fieldRequired}
                  />
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </SectionAdditionalProvisionsLeadSection>
      {currentState === 'IA' && <SuperfundDisclosureIA />}
      {currentState === 'MD' && (
        <>
          <HabitabilityMD />
          {leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.baltimore && (
            <FloodDisclosureMD />
          )}
        </>
      )}
      {currentState === 'WI' && (
        <div className={styles.section}>
          <CodeViolations />
        </div>
      )}
      {currentState === 'WA' && (
        <div className={styles.section}>
          <FireSafetyDisclosureWA />
          <MoldDisclosureWA />
          {leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.tacoma && (
            <>
              <OccupantDisclosureWA />
              <UninhabitableSpaceDisclosureWA />
            </>
          )}
        </div>
      )}
      {currentState === 'ME' && (
        <>
          <div className={styles.section}>
            <BedbugReportingDisclosureME />
          </div>
          <div className={styles.section}>
            <RadonDisclosureME />
          </div>
          <div className={styles.section}>
            <EnergyEfficiencyDisclosureME />
          </div>
        </>
      )}
      {currentState === 'NJ' && (
        <div className={styles.section}>
          <TruthInRenting />
          <FloodZone />
        </div>
      )}
      {currentState === 'NY' && (
        <NewYorkAdditionalProvisions
          leaseRiderUploadFiles={leaseRiderUploadFiles}
          leaseRiderFilesFilter={leaseRiderFilesFilter}
          bedBugsUploadFiles={bedBugsUploadFiles}
          bedBugsFilesFilter={bedBugsFilesFilter}
          handleRemoveAttachment={handleRemoveAttachment}
          attachmentTypes={attachmentTypes}
          leaseAgreementSelection={leaseAgreementSelection}
        />
      )}
      {currentState === 'CA' && (
        <>
          <div className={styles.section}>
            <RentControlDisclosureCA />
          </div>
          <div className={styles.section}>
            <AsbestosNoticeCA />
          </div>
          <div className={styles.section}>
            <DrugDisclosureCA />
          </div>
          <div className={styles.section}>
            <FloodDisclosureCA />
          </div>
          <div className={styles.section}>
            <DeathDisclosureCA />
          </div>
          <div className={styles.section}>
            <MilitaryTestingDisclosureCA />
          </div>
          <div className={styles.section}>
            <MoldDisclosureCA />
          </div>
          <div className={styles.section}>
            <BedbugReportingDisclosureCA />
          </div>
        </>
      )}
      {currentState === 'IN' && (
        <>
          <div className={styles.section}>
            <FloodDisclosureIN />
          </div>
        </>
      )}
      {currentState === 'AZ' && (
        <>
          <div className={styles.floodSection}>
            <BedbugReportingDisclosureAZ />
          </div>
          <div className={styles.floodSection}>
            <PoolDisclosureAZ />
          </div>
        </>
      )}
      {currentState === 'GA' && (
        <div className={styles.section}>
          <FloodDisclosure />
        </div>
      )}
      {currentState === 'OK' && (
        <div className={styles.section}>
          <OKFloodDisclosure />
        </div>
      )}
      {currentState === 'TX' && (
        <div className={styles.section}>
          <TXFloodDisclosure />
        </div>
      )}
      {currentState === 'OR' && (
        <>
          <div className={styles.section}>
            <PendingSuitsOR />
          </div>
          <div className={styles.section}>
            <ORFloodDisclosure />
          </div>
        </>
      )}
      {currentState === 'VA' && (
        <div className={styles.section}>
          <MilitaryInstallationDisclosureVA />
        </div>
      )}
      {currentState === 'VA' && (
        <div className={styles.section}>
          <DrywallDisclosureVA />
        </div>
      )}
      {currentState === 'VA' && (
        <div className={styles.section}>
          <MethamphetamineManufacturingVA />
        </div>
      )}
      {currentState === 'VA' && (
        <div className={styles.section}>
          <MoldDisclosureVA />
        </div>
      )}
      {currentState === 'MT' && (
        <div className={styles.section}>
          <MethamphetamineManufacturingMT />
        </div>
      )}
      {currentState === 'MT' && (
        <div className={styles.section}>
          <MoldDisclosureMT />
        </div>
      )}
      {currentState === 'CO' && (
        <div className={styles.section}>
          <RadonDisclosureCO />
        </div>
      )}
      {currentState === 'MN' && (
        <>
          <div className={styles.section}>
            <InspectionCondemnationMN />
          </div>
          <div className={styles.section}>
            <FinancialDistressDisclosureMN />
          </div>
        </>
      )}
      {currentState === 'CT' && (
        <>
          <div className={styles.section}>
            <CommonInterestCommunityDisclosureCT />
          </div>
          <div className={styles.section}>
            <BedbugReportingDisclosureCT />
          </div>
          <div className={styles.section}>
            <CTSprinklerDisclosure />
          </div>
        </>
      )}
      {currentState === 'UT' && (
        <div className={styles.section}>
          <MethamphetamineManufacturing />
        </div>
      )}
      {currentState === 'SD' && (
        <div className={styles.section}>
          <DisclosureOfPriorManufacturingOfMeth />
        </div>
      )}
      {currentState === 'DC' && (
        <>
          <div className={styles.section}>
            <RentalSurchargesDC />
          </div>
          <div className={styles.section}>
            <BuildingConditionDisclosureDC />
          </div>
          <div className={styles.section}>
            <CodeViolationsDC />
          </div>
          <div className={styles.section}>
            <OwnershipAndLicensingDisclosureDC />
          </div>
          <div className={styles.section}>
            <MoldDisclosureDC />
          </div>
          <div className={styles.section}>
            <RentControlDisclosure />
          </div>
        </>
      )}
      {currentState === 'IL' && (
        <>
          <div className={styles.section}>
            <RentConcessions />
          </div>
          {!leaseAgreementSelection && (
            <div className={styles.section}>
              <RadeonDisclosure />
            </div>
          )}
        </>
      )}
      <SectionAdditionalProvisionsAttachmentsSection
        attachmentErrors={attachmentErrors}
        standAloneWizard={standAloneWizard}
        leaseId={leaseId}
        currentState={currentState}
        leaseAgreementSelection={leaseAgreementSelection}
        onCreateAccount={onCreateAccount}
      >
        <div className={styles.column}>
          <Field
            component={FileUploadPreview}
            id="attachments"
            name="attachments"
            onRemove={handleRemoveAttachment}
            filter={uploadAttachmentFilter}
          />
          <Field
            component={UploadDropZone}
            id="attachments"
            name="attachments"
            accept={attachmentTypes.join(',')}
            className={styles.dropzone}
            uploadFiles={uploadAttachments}
            icon={PhotosIcon}
            iconProps={{ width: 30, height: 30 }}
            disabled={standAloneWizard}
          />
        </div>
      </SectionAdditionalProvisionsAttachmentsSection>
    </>
  );
};

SectionAdditionalProvisionsFields.propTypes = {
  leaseId: PropTypes.string,
  setPristine: PropTypes.func,
  currentState: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
  propertyRole: PropTypes.string,
  leaseRiderUploadFiles: PropTypes.func,
  bedBugsUploadFiles: PropTypes.func,
  handleRemoveAttachment: PropTypes.func,
  attachmentTypes: PropTypes.array,
  attachmentErrors: PropTypes.array,
  uploadAttachments: PropTypes.func,
  uploadAttachmentFilter: PropTypes.func,
  leaseRiderFilesFilter: PropTypes.func,
  bedBugsFilesFilter: PropTypes.func,
  standAloneWizard: PropTypes.bool,
  onCreateAccount: PropTypes.func,
};

SectionAdditionalProvisionsFields.defaultProps = {
  setPristine: () => null,
  standAloneWizard: false,
  attachmentTypes: [],
  attachmentErrors: [],
};

export default SectionAdditionalProvisionsFields;
