import PropertyRolesEnum from './propertyRolesEnum';

function getInitialMUPFieldDropdownValues(listing, name = 'listing_id') {
  const property_id = listing?.root_listing?.id || listing?.id;
  const unit_id =
    listing?.property_role === PropertyRolesEnum.UNIT
      ? listing?.id
      : listing?.property_role === PropertyRolesEnum.ROOM
      ? listing?.parent_listing?.id
      : null;
  const room_id =
    listing?.property_role === PropertyRolesEnum.ROOM ? listing?.id : null;

  return {
    property_id,
    unit_id,
    room_id,
    [name]: room_id || unit_id || property_id,
  };
}

export default getInitialMUPFieldDropdownValues;
