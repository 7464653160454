import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const BurgerNav = (props) => (
  <svg {...props} viewBox="0 0 20 17" fill={props.color}>
    <path d="M18.378 3.022v-1.51H1.622v1.51h16.756Zm.811 1.511H.811C.363 4.533 0 4.195 0 3.778V.756C0 .338.363 0 .81 0h18.38c.447 0 .81.338.81.756v3.022c0 .417-.363.755-.81.755Zm-.81 4.723V7.744H1.621v1.512h16.756Zm.81 1.51H.811c-.448 0-.811-.338-.811-.755V6.99c0-.417.363-.756.81-.756h18.38c.447 0 .81.339.81.756v3.022c0 .417-.363.756-.81.756Zm-.81 4.723v-1.511H1.621v1.51h16.756Zm.81 1.511H.811C.363 17 0 16.662 0 16.244v-3.022c0-.417.363-.755.81-.755h18.38c.447 0 .81.338.81.755v3.022c0 .418-.363.756-.81.756Z" />
  </svg>
);

BurgerNav.defaultProps = {
  width: 20,
  height: 17,
  color: colors.greyLight,
};

BurgerNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(BurgerNav);
