import React from 'react';
import PropTypes from 'prop-types';

import { linkTo, routes } from '../../../../../helpers/routes';
import Exclamation from '../../../../../icons/streamline/filled/Exclamation';
import { Link, Span } from '../../../../Typography';

import styles from '../../GlobalBanner.module.scss';

const MaintenanceInvoiceUnpaid = ({ maintenance_request }) => {
  return (
    <div className={styles['gb-style-danger']}>
      <Exclamation color="white" />
      <div className={styles.content}>
        <Span weight="bold">ACTION REQUIRED: </Span>
        Maintenance Plus invoice payment failed. No requests can be sent to Lula
        until the payment is processed.
        <Link
          to={linkTo(routes.maintenanceRequest, {
            maintenanceRequestId: maintenance_request.id,
            listingId: maintenance_request.listing_id,
          })}
        >
          Learn More
        </Link>
      </div>
    </div>
  );
};

MaintenanceInvoiceUnpaid.propTypes = {
  maintenance_request: PropTypes.shape({
    listing_id: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default MaintenanceInvoiceUnpaid;
