import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { isLoggedIn } from '../core/auth/authService';
import bannerGraphlQuery from '../graphql/queries/globalBanner.graphql';
import createCtx from '../helpers/createCtx';

import { useConfig } from './ConfigProvider';

export const [useGlobalBanner, GlobalBannerContext] = createCtx('GlobalBanner');

const GlobalBannerProvider = ({ children }) => {
  const isOwner = useConfig()?.IS_OWNER;
  const location = useLocation();
  const skip = !isLoggedIn() || !isOwner;
  const [banner, setBanner] = useState(null);

  const [getBanner, { data, error: errorGettingBanner }] = useLazyQuery(
    bannerGraphlQuery,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (
      ['/logout', '/auth/login'].some((blockedPathname) =>
        location?.pathname?.endsWith(blockedPathname),
      )
    ) {
      setBanner(null);
      return;
    }
    if (!skip && isLoggedIn()) {
      getBanner();
    }
  }, [location, skip]);

  useEffect(() => {
    if (errorGettingBanner & window.atatus) {
      window.atatus.notify({
        error: 'Error getting global banner',
        rawError: errorGettingBanner,
      });
    }
    setBanner(data?.viewer?.globalBanner);
  }, [data]);

  useEffect(() => {
    if (banner) {
      document.body.classList.add('has-global-banner');
    }
    return () => {
      document.body.classList.remove('has-global-banner');
    };
  }, [banner]);

  return (
    <GlobalBannerContext.Provider
      value={{
        banner,
        hasBanner: banner,
        refetchBanner: () => {
          getBanner();
        },
      }}
    >
      {children}
    </GlobalBannerContext.Provider>
  );
};

GlobalBannerProvider.propTypes = {
  banner: PropTypes.object,
};

export default GlobalBannerProvider;
