import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useGlobalBanner } from '../../../providers/GlobalBannerProvider';

import BankAccountErrored from './components/BankAccountErrored';
import MaintenanceInvoiceUnpaid from './components/MaintenanceInvoiceUnpaid';
import MultipleBankAccountsErrored from './components/MultipleBankAccountsErrored';

import styles from './GlobalBanner.module.scss';

const BANNER_TYPES = {
  BANK_ACCOUNT_ERRORED: BankAccountErrored,
  MULTIPLE_BANK_ACCOUNTS_ERRORED: MultipleBankAccountsErrored,
  MAINTENANCE_INVOICE_UNPAID: MaintenanceInvoiceUnpaid,
};

const GlobalBanner = () => {
  const { banner } = useGlobalBanner();

  return <GlobalBannerPresentation banner={banner} />;
};

GlobalBanner.propTypes = {
  onIsBannerShown: PropTypes.func,
};

const GlobalBannerPresentation = ({ banner }) => {
  if (!banner) return null;

  const BannerComponent = BANNER_TYPES[banner.banner_type];

  return (
    <div className={cx(styles.container)}>
      <BannerComponent {...banner.payload} />
    </div>
  );
};

GlobalBannerPresentation.propTypes = {
  banner: PropTypes.shape({
    banner_type: PropTypes.oneOf(Object.keys(BANNER_TYPES)),
    payload: PropTypes.object,
  }),
};

export default GlobalBanner;
