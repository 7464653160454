import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';

import { useSuccessToast } from '../../../components/Toast';
import inviteToApplyPublicMutation from '../../../graphql/mutations/applications/inviteToApplyPublic.graphql';
import useNavigator from '../../../hooks/useNavigator';
import { segmentTracking } from '../../../services/utilities';
import useSignupMutation from '../../auth/signup/useSignupMutation';
import { preparePayloadPropertyDetailsPublicLA } from '../../digital-leases/standalone-wizard/StandaloneLeaseAgreement/preparePayloadPropertyDetailsPublicLA';
import UnsavedChangesPrompt from '../../digital-leases/standalone-wizard/UnsavedChangesPrompt';
import ChooseRentalPropertyStep from '../ChooseRentalPropertyStep';
import InviteRentersToApplyStep from '../InviteRentersToApplyStep';
import SignUpStep from '../SignUpStep';
import SuccessPage from '../SuccessPage';

const steps = ['renters', 'property', 'signup', 'app-type'];

const PublicInviteToApply = ({ location, match }) => {
  const onboardingDataRef = useRef(null);
  const experimentSegmentProperty = onboardingDataRef.current
    ? 'Onboarding Answer Move Up Test Aug 2023 - test_version_6'
    : null;
  const navigate = useNavigator();
  const successToast = useSuccessToast();
  const [data, setData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const signup = useSignupMutation(location);

  const [inviteToApplyPublic] = useMutation(inviteToApplyPublicMutation);

  const me = match.path.split(':')[0];

  useEffect(() => {
    if (!onboardingDataRef.current) {
      onboardingDataRef.current = location?.state?.onboarding_data;
    }
    if (match.params.step === 'renters') {
      setData({});
    } else if (!data.renters?.length) {
      navigate(`${me}renters`, { preserveParams: true });
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [match.params.step]);

  const handleSaveData = (values) => {
    setData({
      ...data,
      ...values,
    });
  };

  const handleError = (error) => {
    const parsedError = error?.graphQLErrors?.[0];

    if (parsedError?.code === 471) {
      window.atatus?.notify({
        message: 'Public Application: Email already in use.',
        error,
      });

      return {
        [FORM_ERROR]: parsedError.message,
      };
    }

    if (parsedError?.code === 500) {
      return {
        [FORM_ERROR]: 'Something went wrong, please try again',
      };
    }

    const message = parsedError?.message || 'Something went wrong';
    return {
      [FORM_ERROR]: message,
    };
  };

  const prepareLeadsData = (leadsData) =>
    leadsData.map((lead) => ({
      first_name: lead.first_name,
      last_name: lead.last_name,
      email: lead.email,
      phone: lead.phone,
    }));

  const handleSignup = async (userData) => {
    setSubmitted(true);
    try {
      await signup({
        ...userData,
        onboarding_data: {
          ...(onboardingDataRef.current
            ? {
                ...Object.keys(onboardingDataRef.current).reduce((acc, key) => {
                  if (key !== 'signupDescription')
                    acc[key] = onboardingDataRef.current[key];
                  return acc;
                }, {}),
              }
            : {}),
        },
        process: 'PUBLIC_APPLICATION',
        signupDescription: onboardingDataRef.current?.signupDescription,
      });

      segmentTracking('account_creation success', {
        location: 'Application Type - Public Application',
        experiment: experimentSegmentProperty,
      });
    } catch (err) {
      return handleError(err);
    }

    try {
      const { renters: leadsData, propertyDetailsData } = data;
      await inviteToApplyPublic({
        variables: {
          property_details:
            preparePayloadPropertyDetailsPublicLA(propertyDetailsData),
          leads_information: prepareLeadsData(leadsData),
        },
      });

      successToast('Application sent!');
      onNext();
    } catch (err) {
      return handleError(err);
    }
  };

  const onNext = (values = {}) => {
    handleSaveData(values);
    if (steps.indexOf(match.params.step) === steps.length - 1) {
      return;
    }

    navigate(`${me}${steps[steps.indexOf(match.params.step) + 1]}`, {
      preserveParams: true,
    });
  };

  const handleSuccessRedirect = () => {
    navigate('/onboarding/mailing-address', {
      preserveParams: true,
    });
  };

  return (
    <>
      <UnsavedChangesPrompt
        when={match.params.step !== 'renters' && !submitted}
      />
      <Switch>
        <Route
          path={`${me}renters`}
          render={() => (
            <InviteRentersToApplyStep
              onNext={onNext}
              initialValues={
                data?.renters?.length ? { renters: data.renters } : {}
              }
              segmentData={{
                experiment: experimentSegmentProperty,
              }}
              isOnboardingExperiment={!!onboardingDataRef.current}
            />
          )}
        />
        <Route
          path={`${me}property`}
          render={() => (
            <ChooseRentalPropertyStep
              onNext={(propertyDetailsData) => {
                onNext({ propertyDetailsData });
              }}
              initialValues={data?.propertyDetailsData}
              segmentData={{
                experiment: experimentSegmentProperty,
              }}
            />
          )}
        />
        <Route
          path={`${me}signup`}
          render={(routeProps) => (
            <SignUpStep
              onNext={handleSignup}
              {...routeProps}
              segmentData={{
                experiment: experimentSegmentProperty,
              }}
            />
          )}
        />
        <Route
          path={`${me}app-type`}
          render={() => (
            <SuccessPage
              onSubmit={handleSuccessRedirect}
              renters={data?.renters}
              segmentData={{
                experiment: experimentSegmentProperty,
              }}
            />
          )}
        />
      </Switch>
    </>
  );
};

PublicInviteToApply.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    path: PropTypes.string,
    params: PropTypes.shape({
      step: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default PublicInviteToApply;
