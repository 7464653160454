import { useQuery } from '@apollo/client';

import rentPaymentsQuery from '../graphql/queries/rentPaymentsQuery.graphql';

export const useRentPaymentRules = (options) => {
  const { data, loading } = useQuery(rentPaymentsQuery, options);

  return {
    isCreateChargeActionDisabled: data?.isCreateChargeActionDisabled || false,
    isRecordPaymentActionDisabled: data?.isRecordPaymentActionDisabled || false,
    hasUserFinishedPaymentsOnboarding:
      data?.hasUserFinishedPaymentsOnboarding || false,
    wantsOfflineTracking: data?.wantsOfflineTracking || false,
    loading,
  };
};
