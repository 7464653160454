import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import PropertyRolesEnum from '../../../../helpers/propertyRolesEnum';
import AdditionalOccupantsIcon from '../../../../icons/Calendar';
import SectionTitle from '../../common/SectionTitle';

import styles from './SectionPeopleOnTheLease.module.scss';

export const SectionPeopleOnTheLeaseAdditionalOccupantsSection = ({
  propertyRole,
  roomRentalAdditionalText,
  children,
}) => {
  return (
    <div className={cx(styles.column, styles.section)}>
      <SectionTitle
        title="Additional Occupants"
        icon={AdditionalOccupantsIcon}
      />
      <label className={cx(styles.secondaryLabel, styles.noBottomMargin)}>
        Will there be any additional occupants?
      </label>
      <p className={styles.description}>
        Additional occupants live at the property but are <strong>not</strong>{' '}
        responsible for the lease and therefore do not sign it (usually children
        or other dependents).{' '}
        {propertyRole === PropertyRolesEnum.ROOM && roomRentalAdditionalText}
      </p>
      {children}
    </div>
  );
};

SectionPeopleOnTheLeaseAdditionalOccupantsSection.propTypes = {
  propertyRole: PropTypes.string,
  roomRentalAdditionalText: PropTypes.string,
  children: PropTypes.node,
};
