import React, { useState } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { useApolloClient, useMutation } from '@apollo/client';
import useWindowSize from '@rooks/use-window-size';
import cx from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Button from '../../../components/Button';
import { loginCometChatUser } from '../../../components/CometChatWorkspace/utils';
import FacebookLogin from '../../../components/FacebookLogin';
import GoogleLogin from '../../../components/GoogleLogin';
import Hr from '../../../components/Hr';
import LoginForm from '../../../components/LoginForm';
import Modal from '../../../components/Modal';
import SidebarMarketing from '../../../components/SidebarMarketing';
import { useErrorToast } from '../../../components/Toast';
import { H2, Paragraph } from '../../../components/Typography';
import { smallScreenWidth } from '../../../constants/media-breakpoints';
import * as authService from '../../../core/auth/authService';
import AppleSignupMutation from '../../../graphql/mutations/appleSignin.graphql';
import { COOKIE_NAMES, getCookies } from '../../../helpers/cookies';
import getDeviceSignature from '../../../helpers/get-device-signature';
import { useLoginRecaptcha } from '../../../helpers/login-recaptcha';
import authPage from '../../../hoc/auth-page';
import AppleIcon from '../../../icons/AppleIcon';
import TriangleError from '../../../icons/TriangleError';
import MarketingLayout from '../../../layout/MarketingLayout';
import { useConfig } from '../../../providers/ConfigProvider';
import handleLoginRequest from '../../../services/handleLoginRequest';
import * as localStorageService from '../../../services/localStorageService';
import {
  getUrlToRedirectAfterLogin,
  segmentTracking,
  setUrlToRedirectAfterLogin,
} from '../../../services/utilities';

import academyLogo from './assets/academy-logo.svg';
import academyBuildingImg from './assets/building-illustration.svg';
import marketingImg from './assets/login-software.png';
import DownloadAppModal from './DownloadAppModal';

import styles from './OwnerLoginPage.module.scss';

const AppleSignupModal = ({ onClose }) => (
  <Modal
    open={true}
    className={styles.appleModal}
    containerClassName={styles.modalContainer}
    onClose={onClose}
  >
    <div className={styles.iconContainer}>
      <TriangleError width={58} height={58} />
    </div>
    <p className={styles.content}>No account associated with your Apple ID</p>
    <Button style={{ maxWidth: 232, marginBottom: 16 }} onClick={onClose}>
      Try Another Log In
    </Button>
    <Button
      style={{ maxWidth: 232 }}
      secondary={true}
      onClick={onClose}
      to={`/auth/signup`}
    >
      Create a new account
    </Button>
  </Modal>
);

AppleSignupModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const OwnerLoginPage = ({ history }) => {
  const apolloClient = useApolloClient();
  const showErrorToast = useErrorToast();
  const { getLoginCaptchaToken } = useLoginRecaptcha();
  const [appleModalOpened, setAppleModal] = useState(false);
  const { IS_OWNER, OWNERS_URL, RENTERS_URL, PRIVATE_BASE_PATH } = useConfig();

  const MOBILE_BREAKPOINT = smallScreenWidth;
  const { innerWidth } = useWindowSize();
  const isMobile = innerWidth <= MOBILE_BREAKPOINT;

  const login = (token) => {
    authService.login(token);
    history.replace('/owners/dashboard');
  };

  const [appleSignup, { loading: appleSignupLoading }] =
    useMutation(AppleSignupMutation);

  const queryParams = queryString.parse(location.search);

  const isAcademyLoginPage = (location.pathname || '').match(/\/academy\//g);
  let redirectPath;
  if (queryParams.redirectUrl && isAcademyLoginPage) {
    redirectPath = (decodeURIComponent(queryParams.redirectUrl).match(
      /https?:\/\/[^(/)]+(.+)/,
    ) || [])[1];
  }

  const onSubmit = async (data) => {
    const captchaToken = await getLoginCaptchaToken();

    return await handleLoginRequest({
      history,
      data,
      captchaToken,
      isAcademyLoginPage,
      redirectPath,
      showErrorToast,
      apolloClient,
      redirectOnSuccess: (response) => {
        if (isAcademyLoginPage && response.sso_url) {
          window.location.href = response.sso_url;
          return;
        }

        const url = getUrlToRedirectAfterLogin();

        if (url) {
          setUrlToRedirectAfterLogin('');
          return history.replace(url);
        }

        if (IS_OWNER && !response.onboarding_completed) {
          return history.replace(`/onboarding/process`);
        }

        let suffix = '';
        if (
          IS_OWNER &&
          response.onboarding_completed &&
          !response.onboarding_all_fields_completed
        ) {
          suffix = '&personalizeFlow=true';
        }

        const accountOptions = (response?.users_accounts || []).filter(
          (ua) => ua.user_role === 'MAIN' || !!ua.confirmed_at,
        ).length;

        const prepareQueryParams = {};
        prepareQueryParams.isFromLogIn = true;
        prepareQueryParams.suffix = suffix;

        if (accountOptions > 1) {
          prepareQueryParams.numberOfAccounts = accountOptions;

          history.push(
            `/auth/select-account?${queryString.stringify(prepareQueryParams)}`,
          );
        } else {
          history.push(
            `${PRIVATE_BASE_PATH}dashboard?${queryString.stringify(
              prepareQueryParams,
            )}`,
          );
        }
      },
    });
  };

  const onAppleSignup = async (response) => {
    const identityToken = get(response, 'authorization.id_token', '');

    let { gclid, msclkid } = queryString.parse(location.search);
    const deviceSignature = getDeviceSignature();
    const cookies = getCookies();

    const cookieGclid = get(cookies, 'gclid', '');
    const cookieGclaw = get(cookies, '_gcl_aw', '');

    const cookieImpact = get(cookies, COOKIE_NAMES.IMPACT_COOKIE_NAME, '');
    let impactClickId;
    if (cookieImpact) {
      const cookieImpactDecoded = decodeURIComponent(cookieImpact);
      if (cookieImpactDecoded.split('|').length > 4) {
        impactClickId = cookieImpactDecoded.split('|')[3];
      }
    }

    // share a sale and click id
    const shareASaleCookie = get(
      cookies,
      COOKIE_NAMES.SHARE_A_SALE_COOKIE_NAME,
      '',
    );
    let ssClickId;
    if (shareASaleCookie) {
      const ssCookieObject = JSON.parse(shareASaleCookie);
      ssClickId = ssCookieObject?.clickId;
    }

    // facebook cookie and click id
    const facebookClickCookie = get(
      cookies,
      COOKIE_NAMES.FACEBOOK_CLICK_COOKIE_NAME,
      '',
    );
    let fbclid;
    if (facebookClickCookie && facebookClickCookie.split('.').length > 3) {
      fbclid = facebookClickCookie.split('.')[3];
    }

    if (cookieGclid) {
      if (cookieGclid.split('.').length > 2) {
        gclid = cookieGclid.split('.')[2];
      } else {
        gclid = cookieGclid;
      }
    }

    if (!cookieGclid && cookieGclaw && cookieGclaw.split('.').length > 2) {
      gclid = cookieGclaw.split('.')[2];
    }

    const cookieMsclkid = cookies._uetmsclkid;

    if (cookieMsclkid) {
      msclkid = cookieMsclkid.slice(4);
    }
    const refId = localStorageService.getItem('ref') || null;

    try {
      const { data } = await appleSignup({
        variables: {
          identityToken,
          firstName: response?.user?.name?.firstName,
          lastName: response?.user?.name?.lastName,
          gclid,
          msclkid,
          impactClickId,
          ssClickId,
          fbclid,
          deviceSignature,
          ...(refId ? { refId } : {}),
        },
      });
      if (data?.signinAppleWeb?.isSignup) {
        return setAppleModal(true);
      }
      if (data?.signinAppleWeb?.messagingToken) {
        loginCometChatUser(data.signinAppleWeb.messagingToken);
      }
      login(data?.signinAppleWeb?.token);
    } catch (error) {
      const parsedError = get(error, `graphQLErrors[0]`, {});
      showErrorToast(parsedError.message);
    }
  };

  return (
    <MarketingLayout
      LogoToReplace={isAcademyLoginPage ? academyLogo : null}
      action={
        <div className={styles.signUpWrapper}>
          {!isAcademyLoginPage && <Paragraph>Don't have an account?</Paragraph>}
          <Button
            style={{ width: isMobile ? 136 : 176 }}
            to={`/auth${isAcademyLoginPage ? '/academy' : ''}/signup`}
            small={true}
            secondary={true}
          >
            {isAcademyLoginPage || isMobile ? 'Sign Up' : 'Landlord Sign Up'}
          </Button>
        </div>
      }
      side={
        isAcademyLoginPage ? (
          <SidebarMarketing
            className={styles.academy}
            title="Get Back To Up Your Landlord Game"
            subtitle="Where great landlords go to learn"
            graphic={academyBuildingImg}
          />
        ) : (
          <SidebarMarketing
            className={styles.marketing}
            title="Manage The Rental Process"
            subtitle="Get back to finding & managing great tenants"
            graphic={marketingImg}
          />
        )
      }
    >
      <div className={styles.container}>
        <DownloadAppModal isRenter={false} />
        {appleModalOpened && (
          <AppleSignupModal onClose={() => setAppleModal(false)} />
        )}
        <div
          className={cx(styles.content, {
            [styles.academyContent]: isAcademyLoginPage,
          })}
        >
          {isAcademyLoginPage ? (
            <>
              <h2 className={cx(styles.title, styles.academyTitle)}>
                Log In to <span className={styles.titleSpan}>The Academy</span>
              </h2>
              <p className={styles.academyDesc}>
                It's the same login information as your existing TurboTenant
                account.
              </p>
            </>
          ) : (
            <div className={styles.landlordTitleWrapper}>
              <H2>Landlord Login</H2>
              <Paragraph>
                Not a landlord?{' '}
                <a href={`${RENTERS_URL}auth/login`}>Log in as a renter</a>.
              </Paragraph>
            </div>
          )}
          {IS_OWNER && (
            <>
              <GoogleLogin
                type="login"
                onSuccess={onSubmit}
                onFailure={() =>
                  showErrorToast(
                    'An error occurred while logging in with Google',
                  )
                }
                className={styles.socialBtn}
              />
              <FacebookLogin
                type="login"
                onSuccess={onSubmit}
                onFailure={() => {
                  showErrorToast(
                    'An error occurred while logging in with FaceBook',
                  );
                  window.atatus &&
                    window.atatus.notify({
                      message:
                        'An error occurred while logging in with FaceBook',
                    });
                }}
                className={styles.socialBtn}
              />
              {!isAcademyLoginPage && (
                <AppleSignin
                  authOptions={{
                    clientId: 'com.turbotenant.web',
                    scope: 'email name',
                    redirectURI: `${OWNERS_URL}redirect`,
                    state: 'state',
                    nonce: 'nonce',
                    usePopup: true,
                  }}
                  uiType="dark"
                  className="apple-auth-btn"
                  noDefaultStyle={false}
                  loading={appleSignupLoading}
                  buttonExtraChildren="Log in with Apple"
                  onSuccess={onAppleSignup}
                  onError={() => showErrorToast('Error during Apple SSO')}
                  skipScript={false}
                  iconProp={{
                    style: {
                      marginTop: '10px',
                      width: '380px',
                      height: '40px',
                      textAlign: 'center',
                    },
                  }}
                  render={(props) => (
                    <button
                      {...props}
                      className={styles.customAppleBtn}
                      onClick={() => {
                        props.onClick();
                        segmentTracking('log_in_with_apple clicked', {
                          location: 'Landlord Login Page',
                        });
                      }}
                    >
                      <AppleIcon />
                      <span className={styles.appleLabel}>
                        Log in with Apple
                      </span>
                    </button>
                  )}
                />
              )}
              <Hr />
            </>
          )}
          <LoginForm onSubmit={onSubmit} history={history} />
        </div>
      </div>
    </MarketingLayout>
  );
};

OwnerLoginPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default authPage(OwnerLoginPage);
