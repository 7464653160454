import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import cx from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import FlatButton from '../../../../components/FlatButton';
import PullingReportMessage from '../../../../components/IncomeInsightsCard/PullingReportMessage';
import LoadingScreen from '../../../../components/LoadingScreen';
import SectionTitleWithIconAndAction from '../../../../components/SectionTitleWithIconAndAction';
import CreditCheckStatusEnum from '../../../../constants/enums/CreditCheckStatusEnum';
import ScreeningReportModalCopyEnum from '../../../../constants/enums/ScreeningReportModalCopyEnum';
import requestScreeningReportForExternalApplicationGQL from '../../../../graphql/mutations/requestScreeningReportForExternalApplication.graphql';
import { getExternalApplication } from '../../../../graphql/queries/externalApplicationQuery.graphql';
import AccountFind from '../../../../icons/AccountFind';
import Resend from '../../../../icons/Resend';
import VerifiedBadgeIcon from '../../../../icons/VerifiedBadge';
import ReportResult from '../../screening-report/ReportResult';
import ScreeningReportModal from '../../screening-report/ScreeningReportModal';

import styles from '../../screening-report/ScreeningReportComponent.module.scss';

/**
 * TODO:
 *
 * Find a way to combine this component with the original screening report and make it reusable
 * We duplicated this component in order to move fast and comply with the deadline. But,
 * there should be only one component for screening report.
 */
const PartnerScreeningReportComponent = ({
  application = {},
  shortVersion,
}) => {
  const [screeningReportModalCopy, setScreeningReportModalCopy] =
    useState(null);

  const [requestScreeningReportMutation, { loading }] = useMutation(
    requestScreeningReportForExternalApplicationGQL,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const closeScreeningModal = () => {
    setScreeningReportModalCopy(null);
  };

  const request = async () => {
    try {
      setScreeningReportModalCopy(null);

      const response = await requestScreeningReportMutation({
        variables: {
          rentalRequestId: application.id,
        },
        refetchQueries: [
          {
            query: getExternalApplication,
          },
        ],
        awaitRefetchQueries: true,
      });

      const res = response?.data?.requestScreeningReportForExternalApplication;

      if (
        [
          CreditCheckStatusEnum.READY_FOR_PULLING,
          CreditCheckStatusEnum.NOT_RECEIVED,
        ].includes(res?.creditCheck?.status)
      ) {
        setScreeningReportModalCopy(
          ScreeningReportModalCopyEnum.PULLING_REPORT,
        );
      }

      if (res?.creditCheck.status === CreditCheckStatusEnum.REQUESTED) {
        setScreeningReportModalCopy(
          ScreeningReportModalCopyEnum.REPORT_REQUESTED,
        );
      } else {
        return close();
      }
    } catch (error) {
      if (get(error, 'graphQLErrors[0].code', '') === 483) {
        return setScreeningReportModalCopy(
          ScreeningReportModalCopyEnum.GENERAL_ERROR,
        );
      }
      setScreeningReportModalCopy(
        ScreeningReportModalCopyEnum.TRANSUNION_ERROR,
      );
    }
  };

  const firstName = application?.first_name || null;

  const screeningReportStatus =
    application?.credit_check?.status || CreditCheckStatusEnum.NOT_REQUESTED;

  const renderIdentityVerified = () => (
    <div className={styles.identityVerified}>
      <div className={styles.verifiedBadge}>
        <VerifiedBadgeIcon className={styles.verifiedBadgeIcon} />
        IDENTITY VERIFIED
      </div>
      {firstName && (
        <span className={styles.verifiedText}>
          TransUnion verified {firstName}'s identity.
        </span>
      )}
    </div>
  );

  const renderReadyForPulling = () => {
    return (
      <div className={styles.cardContainer}>
        <div
          className={cx(styles.descriptionContainer, styles.readyForPulling)}
        >
          <SectionTitleWithIconAndAction
            className={styles.titles}
            title="Screening Report"
            icon={AccountFind}
          />
          <div className={styles.description}>
            The applicant approved the screening request. Their credit,
            background, and eviction check are ready for you to view!
          </div>
          <div className={cx(styles.verifiedWrapper, styles.readyForPulling)}>
            {renderIdentityVerified()}
          </div>
        </div>
        <Button
          secondary
          onClick={request}
          loading={loading}
          id="request_screening_report"
          className={styles.buttonStyles}
        >
          SEE THE REPORT
        </Button>
      </div>
    );
  };

  const renderRequested = () => {
    return (
      <div className={styles.cardContainer}>
        <div className={styles.descriptionContainer}>
          <SectionTitleWithIconAndAction
            className={styles.titles}
            title="Screening Report Requested"
            text="Waiting on Applicant"
            icon={AccountFind}
          />
          <div className={styles.description}>
            We are waiting on the applicant to verify their identity with
            TransUnion.
          </div>
          <FlatButton
            secondary
            id="send_reminder"
            onClick={request}
            icon={Resend}
            className={styles.flatBtn}
          >
            Send reminder
          </FlatButton>
        </div>
      </div>
    );
  };

  const renderDeclined = () => {
    return (
      <div className={styles.cardContainer}>
        <div>
          <span className={styles.mainTitle}>Screening Report:</span>
          <span className={cx(styles.mainTitleNoColor, styles.denied)}>
            Denied Screening Report
          </span>
        </div>
        <div className={styles.descriptionContainer}>
          <div className={styles.description}>
            This applicant denied your request for a screening report. You may
            wish to contact them to learn more.
          </div>
        </div>
      </div>
    );
  };

  const renderNoRequested = () => {
    return (
      <div className={styles.cardContainer}>
        <div className={styles.descriptionContainer}>
          <SectionTitleWithIconAndAction
            className={styles.titles}
            title="Request Screening Report"
            icon={AccountFind}
          />
          <div>
            <div className={styles.description}>
              You can request a TransUnion credit and background report for this
              applicant for FREE. Simply request the report and wait for the
              applicant to verify their identity.
            </div>
          </div>
        </div>
        <Button
          secondary
          onClick={request}
          id="request_screening_report"
          className={styles.buttonStyles}
        >
          REQUEST REPORT
        </Button>
      </div>
    );
  };

  const renderScreeningReport = () => {
    const { screening_report_type } = application || {};

    const report = application?.credit_check?.report
      ? JSON.parse(application.credit_check.report)
      : null;

    const isFrozen = report?.isFrozen;

    if (isFrozen) {
      return <div> Frozen Report </div>;
    }

    if (loading) {
      return <LoadingScreen loading />;
    }

    switch (screeningReportStatus) {
      case CreditCheckStatusEnum.REQUESTED:
        return renderRequested();
      case CreditCheckStatusEnum.NOT_REQUESTED:
        return renderNoRequested();
      case CreditCheckStatusEnum.NOT_RECEIVED:
        return (
          <PullingReportMessage
            shortVersion={shortVersion}
            title="Screening Report"
            titleDescription="Pulling Applicant’s Screening Report…"
          />
        );
      case CreditCheckStatusEnum.PRE_ACCEPTED:
      case CreditCheckStatusEnum.READY_FOR_PULLING:
        return renderReadyForPulling();
      case CreditCheckStatusEnum.RECEIVED:
        return (
          <ReportResult
            application={application}
            shortVersion={shortVersion}
            screening_report_type={screening_report_type}
            showHelpButton={false}
            showIncomeInsightPromo={false}
          />
        );
      case CreditCheckStatusEnum.DECLINED:
        return renderDeclined();
    }
  };

  return (
    <div>
      {renderScreeningReport()}
      <ScreeningReportModal
        open={!!screeningReportModalCopy}
        modalCopy={screeningReportModalCopy}
        loading={loading}
        rentalRequestId={application?.id}
        onClose={closeScreeningModal}
        skipUserQuery
      />
    </div>
  );
};

PartnerScreeningReportComponent.defaultProps = {
  title: 'Request Screening Report',
};

PartnerScreeningReportComponent.propTypes = {
  application: PropTypes.object,
  shortVersion: PropTypes.bool,
};

export default PartnerScreeningReportComponent;
