export const statesList = [
  { title: '', value: undefined },
  { title: 'AK', value: 'AK' },
  { title: 'AL', value: 'AL' },
  { title: 'AR', value: 'AR' },
  { title: 'AZ', value: 'AZ' },
  { title: 'CA', value: 'CA' },
  { title: 'CO', value: 'CO' },
  { title: 'CT', value: 'CT' },
  { title: 'DC', value: 'DC' },
  { title: 'DE', value: 'DE' },
  { title: 'FL', value: 'FL' },
  { title: 'GA', value: 'GA' },
  { title: 'GU', value: 'GU' },
  { title: 'HI', value: 'HI' },
  { title: 'IA', value: 'IA' },
  { title: 'ID', value: 'ID' },
  { title: 'IL', value: 'IL' },
  { title: 'IN', value: 'IN' },
  { title: 'KS', value: 'KS' },
  { title: 'KY', value: 'KY' },
  { title: 'LA', value: 'LA' },
  { title: 'MA', value: 'MA' },
  { title: 'MD', value: 'MD' },
  { title: 'ME', value: 'ME' },
  { title: 'MI', value: 'MI' },
  { title: 'MN', value: 'MN' },
  { title: 'MO', value: 'MO' },
  { title: 'MS', value: 'MS' },
  { title: 'MT', value: 'MT' },
  { title: 'NC', value: 'NC' },
  { title: 'ND', value: 'ND' },
  { title: 'NE', value: 'NE' },
  { title: 'NH', value: 'NH' },
  { title: 'NJ', value: 'NJ' },
  { title: 'NM', value: 'NM' },
  { title: 'NV', value: 'NV' },
  { title: 'NY', value: 'NY' },
  { title: 'OH', value: 'OH' },
  { title: 'OK', value: 'OK' },
  { title: 'OR', value: 'OR' },
  { title: 'PA', value: 'PA' },
  { title: 'PR', value: 'PR' },
  { title: 'RI', value: 'RI' },
  { title: 'SC', value: 'SC' },
  { title: 'SD', value: 'SD' },
  { title: 'TN', value: 'TN' },
  { title: 'TX', value: 'TX' },
  { title: 'UT', value: 'UT' },
  { title: 'VA', value: 'VA' },
  { title: 'VI', value: 'VI' },
  { title: 'VT', value: 'VT' },
  { title: 'WA', value: 'WA' },
  { title: 'WI', value: 'WI' },
  { title: 'WV', value: 'WV' },
  { title: 'WY', value: 'WY' },
];

export const supportedUSTerritories = ['PR', 'VI', 'GU'];

export const tenancyTypes = [
  { title: 'Select', value: undefined },
  { title: 'Own', value: 'OWN' },
  { title: 'Rent', value: 'RENT' },
  { title: 'Live with family/friends', value: 'FAMILY' },
  { title: 'Other', value: 'OTHER' },
];

export const howDidYouHear = [
  { title: '', value: undefined },
  { title: 'Apartments.com', value: 'APARTMENT' },
  { title: 'Redfin', value: 'REDFIN' },
  { title: 'Realtor.com', value: 'REALTOR' },
  { title: 'Craigslist', value: 'CRAIGSLIST' },
  { title: 'Zillow', value: 'ZILLOW' },
  { title: 'Zumper', value: 'ZUMPER' },
  { title: 'Facebook', value: 'FACEBOOK' },
  { title: 'Apartment List', value: 'APARTMENT_LIST' },
  { title: 'Homes.com', value: 'HOMES' },
  { title: 'Hunt', value: 'HUNT' },
  { title: 'Show Me The Rent', value: 'SHOW_ME_THE_RENT' },
  { title: 'Uloop', value: 'ULOOP' },
  { title: 'Apartment Advisor', value: 'APARTMENT_ADVISOR' },
  {
    title: 'College Student Apartments',
    value: 'COLLEGE_STUDENT_APARTMENTS',
  },
  { title: 'College Rentals', value: 'COLLEGE_RENTALS' },
  { title: 'Rentberry', value: 'RENTBERRY' },
  { title: 'RentHop', value: 'RENTHOP' },
  { title: 'RentalSource', value: 'MP_RENTAL_SOURCE' },
  { title: 'Dwellsy', value: 'DWELLSY' },
  { title: 'Referral', value: 'REFERRAL' },
  { title: 'Yard Sign', value: 'YARD_SIGN' },
  { title: 'Other', value: 'OTHER' },
];

export const reportReasons = [
  { label: '', value: undefined },
  { label: 'Fraudulent listing / scam', value: 'FRAUDULENT' },
  { label: 'Fair Housing Violation', value: 'FAIR_HOUSING_VIOLATION' },
  { label: 'Listing no longer available', value: 'NOT_AVAILABLE' },
  { label: 'Other', value: 'OTHER' },
];

export const leadStatuses = [
  { label: 'New', value: 'NEW' },
  { label: 'Contacted', value: 'CONTACTED' },
  { label: 'Invited', value: 'INVITED' },
];

export const keyTypes = [
  { label: 'Property', value: 'Property' },
  { label: 'Garage Door Opener', value: 'Garage Door Opener' },
  { label: 'Mailbox', value: 'Mailbox' },
  { label: 'Other', value: 'OTHER' },
];

export const keyLabels = keyTypes.reduce((acc, item) => {
  acc[item.label.toLowerCase()] = item.label;
  return acc;
}, {});

export const keyValues = keyTypes.reduce((acc, item) => {
  acc[item.value.toLowerCase()] = item.value;
  return acc;
}, {});

export const leadIntakeFormSources = [
  { title: '', value: undefined },
  { title: 'Craigslist', value: 'CRAIGSLIST' },
  { title: 'Zillow', value: 'ZILLOW' },
  { title: 'Zumper', value: 'MP_ZUMPER' },
  { title: 'Facebook', value: 'FACEBOOK' },
  { title: 'Apartment List', value: 'MP_APARTMENT_LIST' },
  { title: 'Realtor.com', value: 'MP_REALTOR' },
  { title: 'Apartments.com', value: 'MP_APARTMENTS' },
  { title: 'Homes.com', value: 'MP_HOMES' },
  { title: 'Hunt', value: 'MP_HUNT' },
  { title: 'Show Me The Rent', value: 'MP_SHOW_ME_THE_RENT' },
  { title: 'Uloop', value: 'MP_ULOOP' },
  {
    title: 'College Student Apartments',
    value: 'MP_COLLEGE_STUDENT_APARTMENTS',
  },
  { title: 'Apartment Advisor', value: 'APARTMENT_ADVISOR' },
  { title: 'College Rentals', value: 'MP_COLLEGE_RENTALS' },
  { title: 'Rentberry', value: 'RENTBERRY' },
  { title: 'RentHop', value: 'RENTHOP' },
  { title: 'Dwellsy', value: 'MP_DWELLSY' },
  { title: 'RentalSource', value: 'MP_RENTAL_SOURCE' },
  { title: 'Referral', value: 'REFERRAL' },
  { title: 'Yard Sign', value: 'YARD_SIGN' },
  { title: 'Other', value: 'OTHER' },
];

export const LISTING_TITLE_MAX_CHARACTER_COUNT = 100;

export const CUSTOM_PRE_SCREENER_ANSWER_MAX_CHARACTER_COUNT = 500;

export const VIP_SUPPORT_ADDON_STATUSES = {
  ACTIVE: 'ACTIVE',
  ACTIVE_LEGACY: 'ACTIVE_LEGACY',
};

export const MAXIMUM_ALLOWED_PAYMENT_AMOUNT = 100000;

export const LAYOUT_IDS = {
  BLANK_PAGE: 'BLANK_PAGE',
  LEGACY: 'LEGACY',
  BLUE_PAGE_SIMPLE: 'BLUE_PAGE_SIMPLE',
  ADMIN: 'ADMIN',
  IN_APP: 'IN_APP',
  APPLICATION_FLOW: 'APPLICATION_FLOW',
};

export const PAYMENT_TRACKING_ONBOARDING_STEPS = {
  CHOOSE_CHARGE_TYPE: 1,
  SETUP_CHARGE: 2,
  REVIEW: 3,
};

export const RENTAL_REQUEST_SOURCES = {
  REDFIN_API: 'redfin_api',
  REDFIN: 'Redfin',
  APPARTMENTS: 'Apartments.com',
  CRAIGSLIST: 'craigslist',
};

export const PAYMENT_REQUESTS_CREATION_SOURCES = {
  ONE_TIME_LATE_FEE_POLICY: 'ONE_TIME_LATE_FEE_POLICY',
  DAILY_LATE_FEE_POLICY: 'DAILY_LATE_FEE_POLICY',
  WORKER: 'WORKER',
  APPLICATION: 'APPLICATION',
};

export const PREMIUM_SUBSCRIPTION_STRIPE_PRODUCT_ID = 'tt-premium-subscription';
