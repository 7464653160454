import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const PaymentsNav = (props) => (
  <svg {...props} viewBox="0 0 20 20" fill={props.color}>
    <path d="M10 0c5.523 0 10 4.478 10 10 0 5.524-4.476 10-10 10-5.522 0-10-4.477-10-10C0 4.478 4.478 0 10 0Zm0 1.277a8.725 8.725 0 0 0 0 17.446 8.724 8.724 0 0 0 0-17.447ZM9.953 3.83c.353 0 .638.286.638.638l.001.702a2.767 2.767 0 0 1 2.174 2.702.638.638 0 1 1-1.277 0 1.49 1.49 0 0 0-.897-1.367v2.92a2.767 2.767 0 0 1 0 5.405v.702a.638.638 0 1 1-1.277 0l-.001-.724a2.767 2.767 0 0 1-2.08-2.68.638.638 0 1 1 1.277 0c0 .575.326 1.074.803 1.322v-2.897a2.767 2.767 0 0 1 0-5.36v-.725c0-.352.287-.638.64-.638Zm.639 6.93v2.735a1.49 1.49 0 0 0 0-2.734ZM9.314 6.55a1.49 1.49 0 0 0 0 2.645Z" />
  </svg>
);

PaymentsNav.defaultProps = {
  width: 20,
  height: 20,
  color: colors.greyLight,
};

PaymentsNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(PaymentsNav);
