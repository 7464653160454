import React from 'react';

import GBBPremiumModal from '../components/GBBPremiumModal';
import { GBBPremiumModalVariantsEnum } from '../components/GBBPremiumModal/helpers';
import { USER_EXPERRIMENTS } from '../components/PremiumSubscription/constants';
import { useUserProfile } from '../core/TTgraphql';

//Im aware this is a hacky solution, since this isn't hoc anymore , but it's the quickest way to get this tests in place
const withBillingPlansTest = (Component) => {
  return (props) => {
    const { user, loading } = useUserProfile();
    const isGBBExperiment =
      user?.active_experiments?.some(
        (e) => e.name === USER_EXPERRIMENTS.GBB_MODAL_EXPERIMENT,
      ) || false;

    if (loading) return null;

    if (isGBBExperiment) {
      const experimentValue = user.active_experiments?.find(
        (exp) => exp.name === USER_EXPERRIMENTS.GBB_MODAL_EXPERIMENT,
      )?.value;

      if (
        Object.values(GBBPremiumModalVariantsEnum).includes(experimentValue) &&
        experimentValue !== GBBPremiumModalVariantsEnum.CONTROL
      ) {
        return <GBBPremiumModal {...props} />;
      }
    }

    return <Component {...props} />;
  };
};

export default withBillingPlansTest;
