import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import queryString from 'query-string';

import { USER_EXPERRIMENTS } from '../../../components/PremiumSubscription/constants';
import * as authService from '../../../core/auth/authService';
import { COOKIE_NAMES, getCookies } from '../../../helpers/cookies';
import getDeviceSignature from '../../../helpers/get-device-signature';
import { getSignupTrackingCookie } from '../../../helpers/signupTrackingData';
import * as localStorageService from '../../../services/localStorageService';
import { segmentTracking } from '../../../services/utilities';

import signUpOwnerQuery from './graphql/signUpOwner.graphql';
import signupGaTracking from './utils/signupGaTracking';
import signupImpactTracking from './utils/signupImpactTracking';

const useSignupMutation = (location) => {
  const [signup] = useMutation(signUpOwnerQuery);
  const onSignup = async (data) => {
    let { gclid, msclkid } = queryString.parse(location.search);
    const deviceSignature = getDeviceSignature();
    const cookies = getCookies();
    const trackId = cookies.hubspotutk || '';

    const cookieGclid = get(cookies, COOKIE_NAMES.GCLID, '');
    const cookieGclaw = get(cookies, COOKIE_NAMES.GCLAW, '');
    const formsExp = get(cookies, COOKIE_NAMES.FORMS_EXP);

    const cookieImpact = get(cookies, COOKIE_NAMES.IMPACT_COOKIE_NAME, '');
    let impactClickId;
    if (cookieImpact) {
      const cookieImpactDecoded = decodeURIComponent(cookieImpact);
      if (cookieImpactDecoded.split('|').length > 4) {
        impactClickId = cookieImpactDecoded.split('|')[3];
      }
    }

    // share a sale cookie and click id
    const shareASaleCookie = get(
      cookies,
      COOKIE_NAMES.SHARE_A_SALE_COOKIE_NAME,
      '',
    );
    let ssClickId;
    if (shareASaleCookie) {
      const ssCookieObject = JSON.parse(shareASaleCookie);
      ssClickId = ssCookieObject?.clickId;
    }

    // facebook cookie and click id
    const facebookClickCookie = get(
      cookies,
      COOKIE_NAMES.FACEBOOK_CLICK_COOKIE_NAME,
      '',
    );
    let fbclid;
    if (facebookClickCookie && facebookClickCookie.split('.').length > 3) {
      fbclid = facebookClickCookie.split('.')[3];
    }

    if (cookieGclid) {
      if (cookieGclid.split('.').length > 2) {
        gclid = cookieGclid.split('.')[2];
      } else {
        gclid = cookieGclid;
      }
    }

    if (!cookieGclid && cookieGclaw && cookieGclaw.split('.').length > 2) {
      gclid = cookieGclaw.split('.')[2];
    }

    const cookieMsclkid = cookies._uetmsclkid;

    if (cookieMsclkid) {
      msclkid = cookieMsclkid.slice(4);
    }
    const refId = localStorageService.getItem('ref') || null;

    const experimentGbbCookie =
      get(cookies, COOKIE_NAMES.EXPERIMENT_GBB, null) || null;

    const signupData = {
      ...data,
      track_id: trackId,
      gclid,
      msclkid,
      impactClickId,
      ssClickId,
      fbclid,
      deviceSignature,
      signup_tracking: getSignupTrackingCookie(),
      experiment_signup_cookies: [
        ...(experimentGbbCookie
          ? [
              {
                name: USER_EXPERRIMENTS.GBB_MODAL_EXPERIMENT,
                value: experimentGbbCookie,
              },
            ]
          : []),
      ],
      ...(refId ? { refId } : {}),
      ...(formsExp ? { formsExp } : {}),
    };

    const {
      data: { signUpOwner: ownerResponse },
    } = await signup({
      variables: { ...signupData },
    });

    if (refId) {
      localStorageService.removeItem('ref');
    }

    signupGaTracking(ownerResponse);

    segmentTracking('account_created', {
      Klaviyo: 'true',
    });

    if (impactClickId) {
      signupImpactTracking(ownerResponse);
    }

    authService.login(ownerResponse.token);
  };

  return onSignup;
};

export default useSignupMutation;
