import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import CheckmarkFilledIcon from '../../../icons/streamline/CheckmarkFilled';
import ReferralIcon from '../../../icons/streamline/Referral';
import { segmentTracking } from '../../../services/utilities';
import Button from '../../Button';
import HelpfulInfoList from '../../HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../HelpfulInfo/HelpfulInfoListItem';
import Modal from '../../Modal';
import { H2, H5, Paragraph } from '../../Typography';

import styles from './CongratsModal.module.scss';

const BENEFITS = [
  {
    title: 'Income Insights',
    description: 'Verify a renter’s income',
  },
  {
    title: 'Lower Screening Fees',
    description: 'Renters pay $45 instead of $55',
  },
  {
    title: 'Waived ACH fees for Tenants',
    description: 'Normally $2 per payment',
  },
  {
    title: 'Marketing Call Forwarding',
    description: 'Keep your phone number private',
  },
  {
    title: 'Unlimited Bank Accounts',
    description: 'Deposits into unlimited number of bank accounts',
  },
];

const CongratsModal = ({ open, onClose, segmentProperties }) => {
  useEffect(() => {
    segmentTracking('gbb_temporary_upgrade_modal loaded', {
      ...segmentProperties,
    });
  }, []);

  const handleOnClose = () => {
    segmentTracking('sounds_good clicked', {
      ...segmentProperties,
    });

    onClose();
  };

  return (
    <Modal
      className={styles.modal}
      open={open}
      onClose={onClose}
      id="CongratsModal"
      autoFocus={false}
      disableOutsideClick
      size="small"
      banner={<ReferralIcon secondaryColor={colors.babyBlue} />}
      bannerClassName={styles.banner}
    >
      <Modal.Header>
        <H2 className={styles.title}>
          Enjoy Premium features for FREE for the next 7 days!
        </H2>
        <Paragraph className={styles.description}>
          Try out these enhanced features to experience everything TurboTenant
          has to offer. After 7 days, you’ll automatically revert back to the
          Pro plan.
        </Paragraph>
      </Modal.Header>

      <Modal.Content className={styles.content}>
        <H5 className={styles.descTitle}>Premium Features:</H5>
        <HelpfulInfoList className={styles.list}>
          {BENEFITS.map((item, index) => {
            return (
              <HelpfulInfoListItem
                key={index}
                className={styles.listItem}
                icon={CheckmarkFilledIcon}
                iconProps={{
                  color: colors.orangeLight,
                }}
              >
                <div>
                  <Paragraph className={styles.itemTitle}>
                    <strong>{item.title}</strong>
                  </Paragraph>
                  <Paragraph className={styles.itemDesc}>
                    {item.description}
                  </Paragraph>
                </div>
              </HelpfulInfoListItem>
            );
          })}
        </HelpfulInfoList>
      </Modal.Content>

      <Modal.Footer>
        <Button className={styles.btn} onClick={handleOnClose}>
          Sounds Good
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CongratsModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  segmentProperties: PropTypes.object,
};

export default CongratsModal;
