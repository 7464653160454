import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import userReiHubPricing from '../../core/hooks/userReiHubPricing';
import { useUserFields } from '../../core/TTgraphql';
import createReiHubSubscription from '../../graphql/mutations/rei_hub/createReiHubSubscription.graphql';
import calculateReiHubPrice from '../../helpers/calculate-reihub-price';
import { createAndSaveIdempotencyKey } from '../../helpers/createAndSaveIdempotencyKey';
import getReiHubPrice from '../../helpers/getReiHubPrice';
import { resetIdempotencyKey } from '../../helpers/resetIdempotencyKey';
import StripeElementsModal from '../../pages/common/stripe/StripeElementsModal';
import { useErrorToast } from '../Toast';

import { REIHUB_DISCOUNT_FACTOR, RENEWAL_FREQUENCIES } from './constants';

const AMOUNT_LABELS = {
  [RENEWAL_FREQUENCIES.MONTHLY]: 'PER MONTH',
  [RENEWAL_FREQUENCIES.QUARTERLY]: 'PER QUARTER',
  [RENEWAL_FREQUENCIES.YEARLY]: 'PER YEAR',
};

const AUTO_RENEWAL_PERIODS = {
  [RENEWAL_FREQUENCIES.MONTHLY]: 'monthly',
  [RENEWAL_FREQUENCIES.QUARTERLY]: (
    <>
      every
      <br />3 months
    </>
  ),
  [RENEWAL_FREQUENCIES.YEARLY]: 'yearly',
};

const ReiHubModalWrapped = ({
  onClose,
  onSuccess,
  refetchQueries,
  unitCount,
  renewalFrequency,
}) => {
  const isQuarterlyExperiment =
    renewalFrequency === RENEWAL_FREQUENCIES.QUARTERLY;
  const title =
    renewalFrequency === RENEWAL_FREQUENCIES.QUARTERLY
      ? 'Rental Accounting Subscription'
      : 'Subscribe to REI Hub';
  const errorToast = useErrorToast();

  const { user } = useUserFields(['reihub_listings_count']);
  const pricingMap = userReiHubPricing(isQuarterlyExperiment);

  const { reihub_listings_count: listings_count, id: userId } = user;

  const pricing =
    unitCount != null && renewalFrequency != null
      ? getReiHubPrice(pricingMap, unitCount, isQuarterlyExperiment)
      : null;

  const monthlyReiHubPrice =
    calculateReiHubPrice(listings_count) * REIHUB_DISCOUNT_FACTOR;

  const [createSubscription] = useMutation(createReiHubSubscription, {
    refetchQueries: refetchQueries?.length
      ? refetchQueries.map((query) => ({ query }))
      : null,
    awaitRefetchQueries: true,
  });

  const onToken = useCallback(async (token) => {
    let error;
    try {
      const idempotencyKey = createAndSaveIdempotencyKey();
      await createSubscription({
        variables: {
          token,
          idempotencyKey,
          unitCount,
        },
      });

      onSuccess();
    } catch (e) {
      const message = get(e, 'graphQLErrors[0].message', 'An error occurred');
      errorToast(message);
      error = e?.graphQLErrors;
    }

    resetIdempotencyKey(error);
  });

  if (!userId) {
    return null;
  }

  let price = monthlyReiHubPrice;
  let slashedPrice = null;

  if (pricing != null) {
    if (renewalFrequency === RENEWAL_FREQUENCIES.QUARTERLY) {
      price = pricing.quarterly_price;
      slashedPrice = pricing.slashedPrice * 3;
    } else if (renewalFrequency === RENEWAL_FREQUENCIES.YEARLY) {
      price = pricing.price;
      slashedPrice = pricing.slashedPrice;
    } else {
      price = monthlyReiHubPrice;
    }
  }

  return (
    <StripeElementsModal
      open
      onClose={() => {
        onClose();
      }}
      onToken={onToken}
      slashedAmount={slashedPrice}
      title={title}
      amount={price}
      buttonLabel={pricing != null ? `Pay $${price}` : 'SUBSCRIBE'}
      amountLabel={
        AMOUNT_LABELS[renewalFrequency] ??
        // NOTICE: this line of code below is here for backwards compatibility
        (pricing ? 'year' : 'month')
      }
      autoRenewPeriod={AUTO_RENEWAL_PERIODS[renewalFrequency]}
      onPayClicked={() => {}}
    />
  );
};

ReiHubModalWrapped.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  refetchQueries: PropTypes.array,
  openingLocation: PropTypes.string,
  unitCount: PropTypes.number,
  renewalFrequency: PropTypes.oneOf(Object.values(RENEWAL_FREQUENCIES)),
};

export default ReiHubModalWrapped;
