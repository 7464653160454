import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const FormsNav = (props) => (
  <svg {...props} viewBox="0 0 22 19" fill={props.color}>
    <path d="M18.711 0c.313 0 .567.239.567.534V11.1h2.155c.313 0 .567.24.567.534v6.831c0 .295-.254.534-.567.534H.567C.254 19 0 18.761 0 18.466v-6.831c0-.295.254-.534.567-.534h2.154v-8.86c0-.294.255-.533.568-.533l4.875-.001V.534c0-.295.255-.534.568-.534h9.98ZM6.351 12.169H1.134v5.764h19.732v-5.764h-5.217v1.174c0 .294-.253.533-.567.533H6.918c-.314 0-.567-.239-.567-.533v-1.174Zm1.813-9.394H3.856l-.001 8.326h3.063c.313 0 .567.24.567.534v1.174l.679-.001V2.775Zm9.98-1.708H9.3l-.001 11.741 5.217.001v-1.174c0-.295.254-.534.567-.534h3.062V1.067Zm-1.247 8.326c.313 0 .567.24.567.534 0 .295-.254.534-.567.534h-6.35c-.314 0-.568-.24-.568-.534 0-.295.254-.534.567-.534h6.35Zm0-1.708c.313 0 .567.24.567.534 0 .295-.254.534-.567.534h-6.35c-.314 0-.568-.24-.568-.534 0-.295.254-.534.567-.534h6.35Zm0-1.707c.313 0 .567.238.567.533 0 .295-.254.534-.567.534h-6.35c-.314 0-.568-.239-.568-.534 0-.295.254-.533.567-.533h6.35Zm0-1.708c.313 0 .567.239.567.533 0 .295-.254.534-.567.534h-6.35c-.314 0-.568-.239-.568-.534 0-.294.254-.533.567-.533h6.35Zm0-1.708c.313 0 .567.239.567.534 0 .294-.254.533-.567.533H12.36c-.313 0-.567-.239-.567-.533 0-.295.254-.534.567-.534h4.536Z" />
  </svg>
);

FormsNav.defaultProps = {
  width: 22,
  height: 19,
  color: colors.greyLight,
};

FormsNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(FormsNav);
