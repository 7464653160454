import React from 'react';
import PropTypes from 'prop-types';

const PartyPopper = ({ color, ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g stroke={color} fillOpacity="0" strokeWidth="1.25">
      <path d="M7.204 9.533l-6.571 12.309c-.233.438-.153.977.197 1.328.352.352.891.432 1.329.197l12.347-6.584"></path>
      <ellipse
        transform="matrix(.707 .707 -.707 .707 12.271 -4.327)"
        cx="11.358"
        cy="12.647"
        rx="5.376"
        ry="2.481"
      ></ellipse>
      <path d="M16.447 12.217c1.938-1.936 5.08-1.936 7.018 0M11.769 7.539c1.938-1.938 1.938-5.078 0-7.018M18.075 8.25l3.509-1.168M15.736 5.912l1.17-3.51"></path>
      <circle cx="15.566" cy="8.251" r=".501"></circle>
      <circle cx="8.134" cy="5.326" r=".5"></circle>
      <circle cx="19.908" cy="4.096" r=".5"></circle>
      <circle cx="19.245" cy="14.1" r=".5"></circle>
    </g>
  </svg>
);

PartyPopper.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

PartyPopper.defaultProps = {
  color: 'currentColor',
  width: 24,
  height: 24,
};

export default PartyPopper;
