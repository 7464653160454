import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const TenantsNav = (props) => (
  <svg {...props} viewBox="0 0 20 19" fill={props.color}>
    <path d="M8.771 10.557c.32 0 .578.254.578.567v2.438c0 .845-.234 1.458-.819 1.686v3.185a.572.572 0 0 1-.578.566H5.494a.572.572 0 0 1-.578-.566v-3.185c-.587-.228-.82-.84-.82-1.687v-2.437c0-.313.26-.567.579-.567H8.77ZM3.036 8.934c.32 0 .578.253.578.566v8.934a.572.572 0 0 1-.578.566.572.572 0 0 1-.578-.566V9.5c0-.313.259-.566.578-.566Zm13.928 0c.32 0 .578.253.578.566v8.934a.572.572 0 0 1-.578.566.572.572 0 0 1-.578-.566V9.5c0-.313.258-.566.578-.566Zm-1.639 1.623c.32 0 .579.254.579.567v2.438c-.001.845-.234 1.458-.82 1.686v3.185a.572.572 0 0 1-.578.566h-2.458a.572.572 0 0 1-.578-.566v-3.185c-.586-.228-.82-.84-.82-1.687v-2.437c0-.313.26-.567.579-.567h4.096ZM8.193 11.69h-2.94v1.87c0 .542.06.653.241.653.32 0 .578.254.578.566v3.088h1.301V14.78c0-.312.26-.566.579-.566.18 0 .24-.112.24-.652V11.69Zm6.554 0h-2.94v1.87c0 .542.06.653.241.653.32 0 .579.254.579.566v3.088h1.3V14.78c0-.312.26-.566.579-.566.18 0 .24-.112.241-.652V11.69ZM6.723 6.497c1 0 1.807.801 1.807 1.785 0 .983-.808 1.784-1.807 1.784-1 0-1.807-.8-1.807-1.784s.807-1.785 1.807-1.785Zm6.554 0c1 0 1.807.801 1.807 1.785 0 .983-.807 1.784-1.807 1.784-1 0-1.807-.8-1.807-1.784s.808-1.785 1.807-1.785ZM10.393.15l9.421 8.528c.235.213.25.57.032.8a.587.587 0 0 1-.817.032L10 1.337.971 9.51a.587.587 0 0 1-.817-.032.558.558 0 0 1 .032-.8L9.607.15c.222-.2.564-.2.786 0Zm-3.67 7.48a.652.652 0 0 0 0 1.304.652.652 0 0 0 0-1.304Zm6.554 0a.652.652 0 0 0 0 1.304.652.652 0 0 0 0-1.304Z" />
  </svg>
);

TenantsNav.defaultProps = {
  width: 20,
  height: 19,
  color: colors.greyLight,
};

TenantsNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(TenantsNav);
