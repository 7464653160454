import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import DocumentIcon from '../../../../icons/Document';
import SectionTitle from '../../common/SectionTitle';

import styles from './SectionPeopleOnTheLease.module.scss';

export const SectionPeopleOnTheLeaseUncommonScenariosSection = ({
  tenantAddressChoice,
  children,
}) => (
  <div
    className={cx(styles.column, styles.section, styles.xSmallSpacing, {
      [styles.smallSpacing]: tenantAddressChoice,
    })}
  >
    <SectionTitle title="Uncommon Scenarios" icon={DocumentIcon} />
    {children}
  </div>
);

SectionPeopleOnTheLeaseUncommonScenariosSection.propTypes = {
  tenantAddressChoice: PropTypes.bool,
  children: PropTypes.node,
};
