import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Accounting = (props) => (
  <svg viewBox="0 0 16 20" {...props}>
    <path
      d="M13.333 0A2.666 2.666 0 0 1 16 2.653v14.694A2.666 2.666 0 0 1 13.333 20H2.667A2.666 2.666 0 0 1 0 17.347V2.653A2.666 2.666 0 0 1 2.667 0ZM7.384 13.469H1.23v3.878c0 .738.574 1.352 1.3 1.422l.137.007 4.717-.001v-5.306Zm7.385 0H8.615v5.306h4.718c.742 0 1.36-.57 1.43-1.291l.006-.137v-3.878Zm-11.319.847.865.869.852-.847.87.866-.856.851.859.861-.875.862-.855-.858-.862.86-.87-.866.866-.863-.868-.873.874-.862Zm9.883 2.01v1.225h-3.282v-1.224h3.282Zm0-1.632v1.224h-3.282v-1.224h3.282ZM7.384 7.755H1.23v4.489h6.154V7.755Zm7.385 0H8.615v4.489h6.154V7.755Zm-10.256.612-.001 1.02h1.026v1.225H4.512v1.02h-1.23v-1.02H2.256V9.388l1.026-.001v-1.02h1.23Zm8.82 1.429v1.224h-3.282V9.796h3.282Zm0-8.572H2.667c-.789 0-1.436.645-1.436 1.43L1.23 6.53h13.539V2.653c0-.738-.573-1.352-1.298-1.422l-.138-.007Zm-.324.409c.745 0 1.35.602 1.35 1.343V4.78c0 .74-.606 1.342-1.35 1.342H2.991c-.744 0-1.35-.602-1.35-1.342V2.976c0-.741.605-1.343 1.35-1.343Zm0 1.224H2.991a.12.12 0 0 0-.12.119V4.78a.12.12 0 0 0 .12.118h10.018a.12.12 0 0 0 .12-.118V2.976a.12.12 0 0 0-.12-.119Z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
);

Accounting.defaultProps = {
  width: 16,
  height: 20,
  color: colors.greyLight,
};

Accounting.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Accounting);
