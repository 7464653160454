import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const GiftNav = (props) => (
  <svg {...props} viewBox="0 0 20 20" fill={props.color}>
    <path d="M15.051.793a2.75 2.75 0 0 1 0 3.83 2.596 2.596 0 0 1-.572.42 7.326 7.326 0 0 1-.772.373h5.68c.339 0 .613.28.613.626v3.333a.619.619 0 0 1-.612.625l-.205-.001v9.376a.619.619 0 0 1-.612.625H1.43a.619.619 0 0 1-.613-.625V9.999L.612 10A.619.619 0 0 1 0 9.375V6.042c0-.346.274-.625.612-.625h5.68a7.326 7.326 0 0 1-.771-.374 2.596 2.596 0 0 1-.572-.42 2.749 2.749 0 0 1 0-3.83 2.616 2.616 0 0 1 3.752 0c.146.149.28.345.414.59.255.467.514 1.126.779 1.938l.105.335.107-.335c.266-.815.526-1.477.782-1.945.133-.242.266-.436.41-.583a2.616 2.616 0 0 1 3.753 0ZM6.122 10 2.041 10v8.75l4.081-.001v-8.75Zm5.714 0h-4.49v8.75h4.49v-8.75Zm6.123.001-4.898-.001v8.75l4.898.001V10ZM6.122 6.666H1.224V8.75l4.898-.001V6.666Zm5.714 0h-4.49v2.083h4.49V6.666Zm6.94 0H13.06v2.083l5.715.001V6.667Zm-6.612-4.989c-.052.053-.125.16-.207.31-.21.383-.446.982-.69 1.729-.14.43-.275.889-.403 1.352.453-.131.902-.27 1.324-.413.732-.248 1.318-.489 1.693-.703.148-.084.252-.159.304-.212a1.48 1.48 0 0 0 0-2.063 1.409 1.409 0 0 0-2.02 0Zm-6.35 0a1.48 1.48 0 0 0 0 2.063c.053.053.157.128.305.212.375.214.961.455 1.693.703.422.143.87.282 1.324.412a31.517 31.517 0 0 0-.404-1.351c-.242-.744-.477-1.341-.686-1.725a1.561 1.561 0 0 0-.21-.314 1.409 1.409 0 0 0-2.021 0Z" />
  </svg>
);

GiftNav.defaultProps = {
  width: 20,
  height: 20,
  color: colors.greyLight,
};

GiftNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(GiftNav);
