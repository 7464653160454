import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { FORM_ERROR } from 'final-form';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import MarketingImg from '../../../assets/marketing/product-overview.png';
import BackButton from '../../../components/BackButton';
import Button from '../../../components/Button';
import Hr from '../../../components/Hr';
import SidebarMarketing from '../../../components/SidebarMarketing';
import SignupForm from '../../../components/SignupForm';
import SocialSignup from '../../../components/SocialSignup';
import authPage from '../../../hoc/auth-page';
import MarketingLayout from '../../../layout/MarketingLayout';
import { segmentTracking } from '../../../services/utilities';
import LoadAcademyModal, {
  useOpenAcademy,
} from '../../owners/academy/LoadAcademyModal';

import {
  BackButtonWrapper,
  backStepPath,
  centerContent,
  getCurentTest,
  isWiderContent,
  onSignupSucess,
  personalizeAfterSignup,
  TestDependableShow,
  TestHideForm,
  TestHR,
  TestSidebar,
  TestTitle,
} from './ABtestsHelper/FlowPicker';
import { TestProvider } from './ABtestsHelper/TestProvider';
import useAdditionaSignupData from './ABtestsHelper/useAdditionaSignupData';
import RenterSignupOptions from './RenterSignupOptions';
import useSignupMutation from './useSignupMutation';
import WhichDescribeYouSection from './WhichDescribeYouSection';

import styles from './SignupPage.module.scss';

const SignupPage = ({ location, history, match }) => {
  const onboarding_personalize_flow = personalizeAfterSignup();
  const { type: preselectOption, redirectUrl } = queryString.parse(
    location.search,
  );
  const signup = useSignupMutation(location);
  const currentTestSignUpOnboarding = getCurentTest();

  const [openAcademyModal, openAcademy] = useOpenAcademy();

  const [selected, setSelected] = useState(
    preselectOption || !match.isExact ? 'landlord' : undefined,
  );

  const [additionalSignupData, setAdditionalSignupData] =
    useAdditionaSignupData({});

  const academySignup = !!(redirectUrl || '').match(/(learnworlds|academy)/g);

  const testPath =
    !match.isExact &&
    location?.pathname?.substring(location?.pathname?.lastIndexOf('/') + 1);

  const backPath = backStepPath(testPath);

  const widerContent = isWiderContent(testPath);

  useEffect(() => {
    segmentTracking('onboarding started', {
      location: 'Sign Up Flow',
      experiment: `Onboarding Answer Move Up Test Aug 2023 - ${currentTestSignUpOnboarding}`,
    });
  }, []);

  const onSubmit = async (data) => {
    try {
      segmentTracking('sign_up clicked', {
        location: 'Sign Up Flow',
        experiment: `Onboarding Answer Move Up Test Aug 2023 - ${currentTestSignUpOnboarding}`,
      });

      await signup({
        ...data,
        ...additionalSignupData,
        onboarding_personalize_flow,
        signup_test: currentTestSignUpOnboarding,
      });

      segmentTracking('account_created', {
        location: 'Sign Up Flow',
        experiment: `Onboarding Answer Move Up Test Aug 2023 - ${currentTestSignUpOnboarding}`,
      });

      if (academySignup) {
        return openAcademy();
      }

      await onSignupSucess(history, additionalSignupData?.process);
    } catch (error) {
      const parsedError = get(error, `graphQLErrors[0]`) || {};
      if (parsedError && parsedError.code === 471) {
        // return email error
        return {
          email: parsedError.message,
        };
      }
      if (parsedError && parsedError.code === 500) {
        return {
          [FORM_ERROR]: 'Something went wrong, please try again',
        };
      }
      const message = parsedError.message || 'Something went wrong';
      return {
        [FORM_ERROR]: message,
      };
    }
  };

  const center = centerContent();

  const onSelectUserType = (userType) => {
    if (userType === 'landlord') {
      segmentTracking('landlord clicked', {
        location: 'Sign Up Flow',
        experiment: `Onboarding Answer Move Up Test Aug 2023 - ${currentTestSignUpOnboarding}`,
      });
    }

    setSelected(userType);
  };

  return (
    <TestProvider
      additionalSignupData={additionalSignupData}
      setAdditionalSignupData={setAdditionalSignupData}
      selected={selected}
    >
      <MarketingLayout
        action={
          <Button
            style={{ width: 176 }}
            to={`/auth/login${history?.location?.search || ''}`}
            small={true}
            secondary={true}
          >
            Landlord Login
          </Button>
        }
        side={
          <TestSidebar>
            <SidebarMarketing
              title="Advertising, Screening, And More"
              subtitle="As an independent landlord, manage your rental yourself."
              graphic={MarketingImg}
            />
          </TestSidebar>
        }
      >
        <div
          className={cx(styles.container, {
            [styles.centerContent]: center,
          })}
        >
          {!match.isExact && (
            <BackButtonWrapper path={testPath}>
              {({ setLayout }) => (
                <BackButton
                  onClick={() => {
                    setLayout();
                    if (backPath) {
                      return history.push(`/auth/signup/${backPath}`);
                    } else {
                      history.push('/auth/signup');
                      setSelected(undefined);
                    }
                  }}
                  className={cx(styles.backButton)}
                />
              )}
            </BackButtonWrapper>
          )}
          <div
            className={cx(styles.content, {
              [styles.widerContent]: widerContent,
            })}
          >
            <h2
              className={cx(styles.title, { [styles.centerTitle]: !selected })}
            >
              <TestTitle>Sign up for TurboTenant</TestTitle>
            </h2>
            <TestDependableShow isSelected={selected}>
              <WhichDescribeYouSection
                value={selected}
                onChange={onSelectUserType}
              />
            </TestDependableShow>

            {selected ? (
              <TestHR>
                <Hr />
              </TestHR>
            ) : null}
            {selected === 'landlord' ? (
              <TestHideForm>
                <SocialSignup
                  className={styles.social}
                  onboarding_personalize_flow={onboarding_personalize_flow}
                  additionalSignupData={additionalSignupData}
                />
                <SignupForm
                  onboarding_personalize_flow={onboarding_personalize_flow}
                  onSubmit={onSubmit}
                />
                <LoadAcademyModal open={openAcademyModal} />
              </TestHideForm>
            ) : null}
            {selected === 'renter' ? <RenterSignupOptions /> : null}
          </div>
        </div>
      </MarketingLayout>
    </TestProvider>
  );
};

SignupPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default authPage(SignupPage);
