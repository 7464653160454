import React, { useEffect, useState } from 'react';
import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import { TTfetch } from '../../../core/TTfetch';

import styles from './StripePaymentRequestButton.module.scss';

const USE_PAYMENT_INTENT = false;
const DEFAULT_CURRENCY = 'usd';
const DEFAULT_COUNTRY = 'US';
const DEFAULT_PAYMENT_METHOD_TYPE = 'card';

const StripePaymentRequestButton = ({
  amount,
  description,
  onToken,
  showOr = false,
  onMakePaymentAvailable,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (!stripe || !elements) return;

    const pr = stripe.paymentRequest({
      currency: DEFAULT_CURRENCY,
      country: DEFAULT_COUNTRY,
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        amount: amount * 100,
        label: description,
      },
    });

    pr.canMakePayment()
      .then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }

        onMakePaymentAvailable && onMakePaymentAvailable(!!result);
      })
      .catch((e) => console.log(e));

    /**
     * NOTICE:
     *
     * PaymentIntents version does not work as of now.
     * We need to adapt the backend to use this functionality.
     *
     */
    if (USE_PAYMENT_INTENT) {
      pr.on('paymentmethod', async (e) => {
        // TODO: implment http endpoint or mutation to create the payment intent.
        const { clientSecret } = (
          await TTfetch('/create-payment-intent', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              paymentMethodType: DEFAULT_PAYMENT_METHOD_TYPE,
              currency: DEFAULT_CURRENCY,
            }),
          })
        ).json();

        const { paymentIntent, error } = await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: e.paymentMethod.id },
          { handleActions: false },
        );

        if (error) e.complete('fail');
        else {
          e.complete('success');

          if (paymentIntent.status === 'requires_action') {
            stripe.confirmCardPayment(clientSecret);
          }
        }
      });
    } else {
      pr.on('token', async (e) => {
        if (e.token != null) {
          try {
            await onToken(e.token.id, {
              zipCode: e.token?.card?.address_zip,
              cardHolder: e.token?.card?.name,
            });

            e.complete('success');
          } catch (err) {
            console.error(err);
            e.complete('fail');
          }
        } else {
          e.complete('fail');
        }
      });
    }
  }, [stripe, elements]);

  if (!paymentRequest) return null;

  return (
    <div className={styles.stripeButtonContainer}>
      <PaymentRequestButtonElement
        options={{
          paymentRequest,
          style: {
            paymentRequestButton: {
              height: '48px',
            },
          },
        }}
      />
      {showOr && (
        <p className={styles.or}>
          <span>Or</span>
        </p>
      )}
    </div>
  );
};

export default StripePaymentRequestButton;
