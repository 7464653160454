import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useConfig } from '../../../../providers/ConfigProvider';
import Button from '../../../Button';
import { MODAL_LOCATIONS } from '../../constants';

import styles from './SinglePaymentCard.module.scss';

const SinglePaymentCard = ({
  className,
  openLocation,
  action,
  hideButtons,
}) => {
  const {
    ESIGNATURE_FEE,
    LEASE_ADDENDUM_FEE,
    LEASE_AGREEMENT_FEE,
    FORMS_PACK_PRICE,
  } = useConfig();

  const texts = {
    [MODAL_LOCATIONS.E_SIGN]: {
      header: 'Single E-Sign',
      price: `$${ESIGNATURE_FEE}`,
      description: 'Get one document signed through TurboTenant.',
    },
    [MODAL_LOCATIONS.LEASE]: {
      header: 'Single Lease',
      price: `$${LEASE_AGREEMENT_FEE}`,
      description: 'Get a single-use lease agreement with e-sign included.',
    },
    [MODAL_LOCATIONS.FORMS]: {
      header: 'Forms Pack',
      price: `$${FORMS_PACK_PRICE}`,
      description: '32 essential forms for all stages of the rental process.',
    },
    [MODAL_LOCATIONS.ADDENDUM]: {
      header: 'Single Addendum',
      price: `$${LEASE_ADDENDUM_FEE}`,
      description: 'Get a single-use addendum with e-sign included.',
    },
  };
  return (
    <div
      className={cx(styles.container, className, {
        [styles.hideButtons]: hideButtons,
      })}
    >
      <div className={styles.leftColumn}>
        <h5 className={styles.header}>{texts[openLocation].header}</h5>
        <div className={styles.priceContainer}>
          <h4 className={styles.price}>{texts[openLocation].price}</h4>
          <p className={styles.billedOnce}>billed once</p>
        </div>
      </div>
      <div className={styles.rightColumn}>
        <p className={styles.description}>{texts[openLocation].description}</p>
        {!hideButtons && (
          <Button
            onClick={action}
            secondary
            className={styles.btn}
            id="one-time-payment"
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

SinglePaymentCard.propTypes = {
  className: PropTypes.string,
  openLocation: PropTypes.oneOf([
    MODAL_LOCATIONS.E_SIGN,
    MODAL_LOCATIONS.LEASE,
    MODAL_LOCATIONS.FORMS,
    MODAL_LOCATIONS.ADDENDUM,
  ]),
  action: PropTypes.func,
  hideButtons: PropTypes.bool,
};

export default SinglePaymentCard;
