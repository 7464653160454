import React, { useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { parse } from 'query-string';

import BackButton from '../../../../components/BackButton';
import LoadingScreen from '../../../../components/LoadingScreen';
import Logo from '../../../../components/Logo';
import ProgressBar from '../../../../components/ProgressBar';
import { useLeases, useUserProfile } from '../../../../core/TTgraphql';
import paymentRequestRulesQueryGQL from '../../../../graphql/queries/payment_request_rules.graphql';
import { getUserBankAccounts } from '../../../../helpers/getUserBankAccounts';
import hasOwnerFinishedPaymentsOnboarding from '../../../../helpers/hasOwnerFinishedPaymentsOnboarding';
import normalizeLateFeeAmount from '../../../../helpers/normalizeLateFeeAmount';
import { useRentPaymentRules } from '../../../../hooks/useRentPaymentRules';
import { useConfig } from '../../../../providers/ConfigProvider';
import { segmentTracking } from '../../../../services/utilities/segment';
import { prepareChargeForMutation } from '../../helpers/mappers';
import {
  useCreatePaymentRule,
  useDeletePaymentRequestRule,
  useEditPaymentRequestRules,
  usePaymentRequestRules,
} from '../../usePayments';

import ChargesWrapper from './components/ChargesWrapper';
import CollectionWrapper from './components/CollectionWrapper';
import HowPaymentsWork from './components/HowPaymentsWork';
import LeasesOnboardingWrapper from './components/LeasesOnboardingWrapper';
import PropertyDetailsNewOnboardingWrapper from './components/PropertyDetailsNewOnboardingWrapper';
import SetupConfirmationWrapper from './components/SetupConfirmationWrapper';

import styles from './OwnersPaymentsOnboarding.module.scss';

const OwnersPaymentsOnboarding = ({ match, history, location }) => {
  const backButtonOverride = useRef();

  const { PRIVATE_BASE_PATH } = useConfig();
  const { wantsOfflineTracking, loading: loadingRentPaymentRules } =
    useRentPaymentRules();

  const PROPERTY_STEP = 1;
  const LEASE_STEP = 2;
  const CHARGES_STEP = 3;
  const BANK_STEP = 4;
  const SUMMARY_STEP = 5;

  const step = parseInt(match?.params?.step || 0);

  const isEdit = match?.params?.isEdit === 'true' || false;

  const { user, loading: loadingUser } = useUserProfile({ polling: false });
  const hasUserFinishedPaymentsOnboarding =
    hasOwnerFinishedPaymentsOnboarding(user);

  useEffect(() => {
    if (hasUserFinishedPaymentsOnboarding) {
      history.replace('/owners/dashboard');
    }
  }, [hasUserFinishedPaymentsOnboarding, history]);

  const isPremiumUser = user.premium_subscription_subscribed;

  const userBankAccounts = getUserBankAccounts(user);

  // If user is not premium, there's no need to display which account is selected
  const bankAccountOptions = user.premium_subscription_subscribed
    ? [
        ...userBankAccounts.map((ba) => ({
          value: ba.id,
          label: ba.nickname || ba.bank_name,
          default: ba.default_for_currency,
        })),
      ]
    : [];

  const getCurrentStep = () => {
    switch (step) {
      case PROPERTY_STEP:
        return 'Property';
      case LEASE_STEP:
        return 'Lease';
      case CHARGES_STEP:
        return 'Charges';
      case BANK_STEP:
        return 'Collection';
    }
  };

  const getNextStepLink = (leaseId) => {
    if (step === SUMMARY_STEP) {
      return `${PRIVATE_BASE_PATH}leases/view/${leaseId}/payments?fromSetupFlow=true${
        wantsOfflineTracking ? '&offlineOnboardingCompleted=true' : ''
      }`;
    }
    if (wantsOfflineTracking && step === PROPERTY_STEP) {
      return `${PRIVATE_BASE_PATH}payments/setup/lease?isOnboarding=true`;
    }
    if (isEdit) {
      return `${PRIVATE_BASE_PATH}payments/onboarding/${SUMMARY_STEP}`;
    } else {
      return `${PRIVATE_BASE_PATH}payments/onboarding/${step + 1}`;
    }
  };

  const onEditClicked = (step) => {
    switch (step) {
      case 'property':
        return history.push(
          `${PRIVATE_BASE_PATH}payments/onboarding/${PROPERTY_STEP}/true`,
        );
      case 'lease':
        return history.push(
          `${PRIVATE_BASE_PATH}payments/onboarding/${LEASE_STEP}/true`,
        );
      case 'charges':
        return history.push(
          `${PRIVATE_BASE_PATH}payments/onboarding/${CHARGES_STEP}/true`,
        );
      case 'bank':
        return history.push(
          `${PRIVATE_BASE_PATH}payments/onboarding/${BANK_STEP}/true`,
        );
    }
  };

  const queryParams = parse(location.search, { parseBooleans: true });
  const {
    leaseId: paramLeaseId,
    tracking: wantsOfflineTrackingFromURL,
    bankStep,
  } = queryParams;

  // if the lease id is present in the route, let's skip the query
  const { leases, loading: leasesLoading } = useLeases({
    skip: !!paramLeaseId,
  });

  const leaseId = paramLeaseId || leases[0]?.id; // the user will never have more than one during the onboarding.

  const [createPaymentRequestRule] = useCreatePaymentRule({
    leaseId,
    refetchQueries: [
      {
        query: paymentRequestRulesQueryGQL,
        variables: {
          lease_id: leaseId,
        },
      },
    ],
  });

  const [deletePaymentRequestRule, { loading: deleteLoading }] =
    useDeletePaymentRequestRule({
      leaseId,
      refetchQueries: [
        {
          query: paymentRequestRulesQueryGQL,
          variables: {
            lease_id: leaseId,
          },
        },
      ],
    });

  const [editPaymentRequestRules] = useEditPaymentRequestRules({
    leaseId,
    refetchQueries: [
      {
        query: paymentRequestRulesQueryGQL,
        variables: {
          lease_id: leaseId,
        },
      },
    ],
  });

  const onChargeCreate = async (charge) => {
    // We need to format end date to include time zone.
    charge.end_date = charge.end_date
      ? moment(
          `${charge.end_date} 00:00 +0000`,
          'YYYY-MM-DD HH:mm Z',
        ).toISOString()
      : charge.end_date;

    const dataToSubmit = prepareChargeForMutation(charge);

    await createPaymentRequestRule({
      variables: {
        leaseId: leaseId || charge.leaseId,
        data: dataToSubmit,
      },
    });
  };

  const onChargeDelete = async (charge) => {
    await deletePaymentRequestRule({
      variables: {
        rule_id: charge.id,
      },
    });
  };

  const onChargeEdit = async (charge) => {
    // We need to format end_date to include time zone and we have different
    // format for Monthly (have charge.start_date) vs One-Time Charges.
    const newEndDate = () => {
      if (charge.start_date) {
        return moment(
          `${charge.end_date} 00:00 +0000`,
          'YYYY-MM-DD- HH:mm Z',
        ).toISOString();
      }

      if (charge.end_date) {
        return moment(
          `${charge.end_date} 00:00 +0000`,
          'MM/DD/YYYY HH:mm Z',
        ).toISOString();
      }

      return charge.end_date;
    };

    // We need clone object to have a separate data for FE and BE
    const dataToSubmit = prepareChargeForMutation({
      ...charge,
      end_date: newEndDate(),
    });

    await editPaymentRequestRules({
      variables: {
        rules: [{ ...dataToSubmit, id: charge.id }],
      },
    });
  };

  const { rules, loading: chargesListLoading } = usePaymentRequestRules({
    variables: { lease_id: leaseId, include_finalized: true },
    skip: !leaseId,
  });

  const charges =
    rules.map((node) => {
      return {
        ...node,
        amount: (node.amount / 100).toFixed(2).toString(),
        late_fee_amount: normalizeLateFeeAmount(node),
      };
    }) || [];

  const monthlyCharges = charges.filter((charge) => charge.type === 'MONTHLY');
  const oneTimeCharges = charges.filter((charge) => charge.type === 'ONE_TIME');

  const getContent = () => {
    const commonProps = {
      onNext: (leaseId) => {
        history.push(getNextStepLink(leaseId));
        switch (step) {
          case LEASE_STEP:
            segmentTracking('lease_step submit', {
              location: 'rp onboarding lease step',
            });
            break;
          case CHARGES_STEP:
            segmentTracking('charges_step submit', {
              location: 'rp onboarding charges step',
            });
            break;
          case BANK_STEP:
            segmentTracking('collection_step submit', {
              location: 'rp onboarding collection step',
            });
            break;
          case SUMMARY_STEP:
            segmentTracking('summary_screen_submit clicked', {
              location: 'rp onboarding summary screen',
            });
            break;
        }
      },
      onSkipCharges: (leaseId) => {
        history.push(getNextStepLink(leaseId));
        return (
          'Charges',
          segmentTracking('charges_step submit', {
            location: 'rp onboarding skip charges step',
          })
        );
      },
      isEdit,
      leasesLoading: leasesLoading,
      deleteLoading: deleteLoading,
      onChargeCreate: onChargeCreate,
      onChargeDelete: onChargeDelete,
      onChargeEdit: onChargeEdit,
      chargesListLoading: chargesListLoading,
      monthlyCharges: monthlyCharges,
      oneTimeCharges: oneTimeCharges,
      leaseId,
    };

    switch (step) {
      case 0:
        return (
          <HowPaymentsWork
            className={styles.paymentsContainer}
            segmentLocation="rp onboarding intro"
            onBack={() => {
              history.push(`/onboarding/process`);
            }}
            action={{
              to: `${PRIVATE_BASE_PATH}payments/onboarding/${step + 1}`,
              label: 'Continue',
              eventName: 'next',
            }}
          />
        );
      case PROPERTY_STEP:
        return (
          <PropertyDetailsNewOnboardingWrapper
            {...commonProps}
            wantsOfflineTracking={wantsOfflineTrackingFromURL}
          />
        );
      case LEASE_STEP:
        return <LeasesOnboardingWrapper {...commonProps} />;
      case CHARGES_STEP:
        return (
          <ChargesWrapper
            {...commonProps}
            leaseId={leaseId}
            loadingUser={loadingUser}
            isPremiumUser={isPremiumUser}
            bankAccountOptions={bankAccountOptions}
          />
        );
      case BANK_STEP:
        return (
          <CollectionWrapper
            bankStep={bankStep || isEdit}
            leaseId={leaseId}
            onNext={() => {
              const nextStep = getNextStepLink();
              history.push(nextStep);
            }}
            overrideBackButtonClick={(override) => {
              backButtonOverride.current = override;
            }}
            history={history}
            location={location}
            onboarding
          />
        );

      case SUMMARY_STEP:
        return (
          <SetupConfirmationWrapper
            showPropertySection={true}
            {...commonProps}
            onEdit={onEditClicked}
          />
        );
    }
  };

  const getBackLink = useCallback(() => {
    return `${PRIVATE_BASE_PATH}payments/onboarding/${step - 1}`;
  }, [queryParams, step]);

  const progressBarOptions = ['Property', 'Lease', 'Charges', 'Collection'];
  return (
    <LoadingScreen loading={loadingUser}>
      <div className={cx(styles.container, { [styles.firstStep]: step === 0 })}>
        <Logo width={162} height={48} className={styles.logo} />
        {step !== 0 && step !== SUMMARY_STEP && !isEdit && (
          <div className={styles.header}>
            {step > 0 && !isEdit && (
              <BackButton
                className={styles.back}
                onClick={(e) => {
                  if (backButtonOverride.current) {
                    e.preventDefault();
                    backButtonOverride.current();
                  }
                }}
                to={getBackLink()}
              />
            )}

            {(!loadingRentPaymentRules || step !== PROPERTY_STEP) && (
              <ProgressBar
                options={progressBarOptions}
                active={getCurrentStep()}
                className={styles.progress}
              />
            )}
          </div>
        )}
        {getContent()}
      </div>
    </LoadingScreen>
  );
};

export default OwnersPaymentsOnboarding;
