import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ToolboxNav = (props) => (
  <svg {...props} viewBox="0 0 20 17" fill={props.color}>
    <path
      fillRule="evenodd"
      d="M10 0c2.105 0 3.85 1.511 4.17 3.487h5.163a.66.66 0 0 1 .667.654v12.205a.66.66 0 0 1-.667.654H.667A.66.66 0 0 1 0 16.346V4.141a.66.66 0 0 1 .667-.654H5.83C6.15 1.512 7.894 0 10 0ZM8 9.153H1.333v6.54h17.334l-.001-6.54H12v1.962a.66.66 0 0 1-.667.654H8.667A.66.66 0 0 1 8 11.115V9.153Zm2.667-.87H9.333v2.179h1.334v-2.18Zm8-3.488H1.333v3.051H8v-.218c0-.328.247-.6.568-.647l.099-.007h2.666a.66.66 0 0 1 .667.654v.218h6.666V4.795ZM10 1.308c-1.367 0-2.511.93-2.812 2.18h5.624c-.301-1.25-1.446-2.18-2.812-2.18Z"
    />
  </svg>
);

ToolboxNav.defaultProps = {
  width: 20,
  height: 17,
  color: colors.greyLight,
};

ToolboxNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ToolboxNav);
